import { StylesProvider } from '@mui/styles';
import { client as apolloClient } from './services/apollo.service';
import { ApolloProvider } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Router as BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider, useDispatch } from 'react-redux';
import { store } from './redux/store';
import { Router } from './components/Router';
import { CssBaseline } from '@mui/material';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { useEffect } from 'react';
import {
  authDisabled,
  authenticationRequest,
  getProvider,
} from './services/auth.service';
import { InteractionType } from '@azure/msal-browser';
import { history } from './history';

const queryClient = new QueryClient();

const App: React.FC = () => (
  <QueryClientProvider client={queryClient}>
    <ApolloProvider client={apolloClient}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={materialOverrides}>
          <CssBaseline />
          <Provider store={store}>
            <MicrosoftAuth>
              <BrowserRouter history={history}>
                <Router />
              </BrowserRouter>
            </MicrosoftAuth>
          </Provider>
        </ThemeProvider>
      </StylesProvider>
    </ApolloProvider>
  </QueryClientProvider>
);

const MicrosoftAuth: React.FC = ({ children }) => {
  if (authDisabled) {
    return <>{children}</>;
  }

  return (
    <MsalProvider instance={getProvider()}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authenticationRequest}
      >
        <MicrosoftAuthInner>{children}</MicrosoftAuthInner>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

const MicrosoftAuthInner: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  // If this component renders, we are logged in. We feed the account info to
  // redux. This happens only when the component is mounted
  useEffect(() => {
    dispatch({ type: 'AAD_LOGIN_SUCCESS' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

const materialOverrides = createTheme({
  palette: {
    primary: {
      main: '#444',
    },
  },
});

export default App;
