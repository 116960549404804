import styled from 'styled-components';

export interface ErrorProps {
  fullscreen?: boolean;
  errorText?: string;
  style?: React.CSSProperties;
}

export const Error: React.FC<ErrorProps> = ({
  fullscreen,
  errorText = 'Something went wrong',
  style,
}) => (
  <ErrorStyling fullscreen={fullscreen ? 1 : undefined} style={style}>
    <div>{errorText}</div>
  </ErrorStyling>
);

const ErrorStyling = styled.div<{ fullscreen: number | undefined }>`
  display: flex;
  justify-content: center;
  padding: 1em;
  margin-top: 40px;

  ${(props) =>
    props.fullscreen === 1 &&
    `
    width: 100vw;
    height: 100vh;
    align-items: center;
  `}
`;
