import { Drawer, Typography, Input } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import React, { useState } from 'react';
import { ProductDrawerContents } from './ProductDrawerContents';
import { applySearchQuery } from '../../utils';
import { ParentProductPreview } from '../../types';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../constants';
import { useCreateProductMutation } from '../../generated/graphql';
import { Queries } from '../../queries/queries';
import { displayUserErrors } from '../../errors';

export interface ProductDrawerProps {
  selectedId: ID | null;
  products: ParentProductPreview[];
  byCustomer?: ID;
}

export const ProductDrawer: React.FC<ProductDrawerProps> = ({
  selectedId,
  products,
  byCustomer,
}) => {
  const [filter, setFilter] = useState('');

  const filteredProducts =
    products == null ? [] : applySearchQuery(products, filter);
  const history = useHistory();

  const [create, { loading: creating }] = useCreateProductMutation();

  const createNewProduct = () => {
    const mutation = create({
      variables: {
        input: {
          parentId: null,
        },
      },
      refetchQueries: [Queries.Products],
      awaitRefetchQueries: true,
    }).then((result) => {
      const product = result.data?.result.product?.id;
      if (product != null) {
        history.push(Routes.Products.singlePath(product));
      }
      return result;
    });

    displayUserErrors('result', mutation, true);
  };

  return (
    <ProductDrawerStyle
      variant="persistent"
      anchor="left"
      open
      classes={{ paper: 'paper' }}
    >
      <div className="header">
        <Typography variant="h5">Products</Typography>
        <Button
          className="create-product"
          onClick={() => createNewProduct()}
          startIcon={<AddCircleIcon />}
          variant="contained"
          disableElevation
          size="large"
          disabled={creating}
        >
          {creating ? '...' : 'New Product'}
        </Button>
      </div>

      <Input
        className="search"
        style={{ margin: '20px 10px' }}
        onChange={(e) => setFilter(e.target.value)}
        value={filter}
        placeholder="Search"
      />

      <ProductDrawerContents
        parentProducts={filteredProducts}
        selectedId={selectedId}
        byCustomer={byCustomer}
      />
    </ProductDrawerStyle>
  );
};

const ProductDrawerStyle = styled(Drawer)`
  .create-product {
    margin: 15px 0;
    width: min-content;
    align-self: center;
    height: 42px;
    white-space: nowrap;
  }
  .header {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 1em;
    justify-content: space-between;
  }
  .paper {
    z-index: 30;
    position: fixed;
    top: 64px;
    height: calc(100vh - 64px);

    width: 100%;
    @media (min-width: 600px) {
      width: 350px;
    }
  }

  .search {
    background: none;
    margin: 20px 10px;
  }

  .list {
    overflow-y: auto;
    flex-grow: 1;
    .MuiAccordion-root {
      width: 100%;
    }
  }
`;
