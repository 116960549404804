import { AccordionDetails, List, ListItem, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../constants';
import { ParentProductPreview } from '../../types';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';

export interface ProductDrawerContentsProps {
  parentProducts: ParentProductPreview[];
  selectedId: ID | null;
  byCustomer?: ID;
}

export const ProductDrawerContents: React.FC<ProductDrawerContentsProps> = ({
  parentProducts,
  selectedId,
  byCustomer,
}) => {
  const history = useHistory();
  const specSheetMatch = Routes.SpecSheet.useParams();

  const [expanded, setExpanded] = useState<ID>();
  const handleExpand = (parent: ID) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? parent : undefined);
  };

  useEffect(() => {
    if (selectedId != null && expanded == null) {
      const toExpand = parentProducts.find(
        (parent) =>
          parent.id === selectedId ||
          parent.children.find((child) => child.id === selectedId),
      );
      if (toExpand != null) {
        setExpanded(toExpand.id);
      }
    }
  }, [selectedId, expanded, parentProducts]);

  return (
    <List className="list">
      {parentProducts.length === 0 && (
        <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>
            Nothing to see here...
            <span role="img" aria-label="nice">
              👌
            </span>
          </Typography>
        </ListItem>
      )}

      {parentProducts.map((parent) => {
        const toShow =
          byCustomer == null
            ? parent
            : parent.children.find(
                (child) => child.assignedCustomer?.id === byCustomer,
              ) ?? parent;

        return (
          <Accordion
            onClick={() =>
              specSheetMatch?.customerId != null
                ? history.push(
                    Routes.SpecSheet.path({
                      customerId: specSheetMatch.customerId,
                      productId: toShow.id,
                    }),
                  )
                : history.push(Routes.Products.singlePath(toShow.id))
            }
            key={parent.id}
            expanded={expanded === parent.id}
            onChange={handleExpand(parent.id)}
          >
            <AccordionSummary
              expandIcon={
                !byCustomer && parent.children.length > 0 && <ExpandMoreIcon />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>{toShow.name || 'Unknown Product'}</Typography>
            </AccordionSummary>

            {byCustomer == null && parent.children.length > 0 && (
              <AccordionDetails>
                {parent.children.map((child) => (
                  <ListItem
                    button
                    key={child.id}
                    style={
                      child.id === selectedId
                        ? { background: 'rgba(0, 0, 0, 0.2)' }
                        : undefined
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      history.push(Routes.Products.singlePath(child.id));
                    }}
                  >
                    {(child.assignedCustomer?.name ?? 'Unknown Customer') ||
                      'Customer without Name'}
                  </ListItem>
                ))}
              </AccordionDetails>
            )}
          </Accordion>
        );
      })}
    </List>
  );
};
