import { configureStore, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { CustomerPreview, Product } from '../types';

interface PrinterSliceState {
  products: Product[];
  customer: CustomerPreview | null;
  unitNumber: string;
}
const initialState: PrinterSliceState = {
  products: [],
  customer: null,
  unitNumber: '',
};

export const printerStoreSlice = createSlice({
  name: 'printerStore',
  initialState,
  reducers: {
    addToPrint: (state, action: PayloadAction<Product>) => {
      !state.products.find((x) => x.id === action.payload.id) &&
        state.products.push(action.payload);
    },
    removeFromPrint: (state, action: PayloadAction<number>) => {
      state.products.splice(action.payload, 1);
    },
    clearPrintList: (state) => {
      state.products = [];
    },
    addCustomer: (state, action: PayloadAction<CustomerPreview>) => {
      state.customer = { id: action.payload.id, name: action.payload.name };
    },
    addUnitNumber: (state, action: PayloadAction<string>) => {
      state.unitNumber = action.payload;
    },
  },
});

const enhancer = Sentry.createReduxEnhancer();

export const store = configureStore({
  reducer: {
    printProducts: printerStoreSlice.reducer,
  },
  enhancers: [enhancer],
});

export const selectProducts = (state: RootState) =>
  state.printProducts.products;
export const selectUnitNumber = (state: RootState) =>
  state.printProducts.unitNumber;
export const selectCustomer = (state: RootState) =>
  state.printProducts.customer;

export const {
  addToPrint,
  removeFromPrint,
  clearPrintList,
  addCustomer,
  addUnitNumber,
} = printerStoreSlice.actions;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
