import React, { useEffect } from 'react';
import { Prompt as RouterPrompt } from 'react-router-dom';

export interface PromptProps {
  when: boolean;
  message: string;
}

export const Prompt: React.VFC<PromptProps> = ({ when, message }) => {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }, [when]);

  return <RouterPrompt when={when} message={message} />;
};
