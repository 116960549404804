import React, { useState } from 'react';
import styled from 'styled-components';
import { Routes } from '../constants';
import { Link as RouterLink } from 'react-router-dom';
import { VersionNumber } from './VersionNumber';
import CompanyLogo from '../assets/company-logo-bare.png';
import PrintIcon from '@mui/icons-material/Print';
import Button from '@mui/material/Button';
import { Toolbar, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { ProductMultiplePrinterModal } from './ProductMultiplePrinterModal';
import { useSelector } from 'react-redux';
import { selectProducts } from '../redux/store';
import { useLocation } from 'react-router-dom';
import { env } from './../runtime-environment'

interface RootContainerProps {
  env: string;
  children: React.ReactNode;
}

const RootContainer: React.FC<RootContainerProps> = ({ env, children }) => {
  return (
    <div style={env === 'staging' ? { border: '5px solid red' } : {}}>
      {children}
    </div>
  )
}

export const Navbar: React.FC = () => {
  const [open, setOpen] = useState(false);
  const addedProducts = useSelector(selectProducts);
  const location = useLocation();

  return (
    <>
      <AppBar position="fixed" style={{ zIndex: 100 }}>
        <RootContainer env={env.profile}>
          <ToolbarStyle>
            <Toolbar className="toolbar">
              <RouterLink
                style={{ textDecoration: 'none' }}
                to={Routes.Home.path()}
              >
                <Typography variant="h6" noWrap className="app-name">
                  Profile Sheets
                </Typography>
              </RouterLink>
              <Button
                variant={'contained'}
                className="print-cart"
                disableElevation
                size="large"
                onClick={() => setOpen(true)}
              >
                {`View Print Jobs (${addedProducts.length > 0 ? addedProducts.length : 0
                  })`}
              </Button>
              {location.pathname === '/print' && (
                <Button
                  startIcon={<PrintIcon />}
                  variant={'contained'}
                  className="print-button"
                  disableElevation
                  size="large"
                  color={'primary'}
                  onClick={() => window.print()}
                >
                  Print
                </Button>
              )}

              <div className="logo-version-container">
                <img src={CompanyLogo} className="logo" alt="Logo" />
                <VersionNumber kind="bar" />
              </div>
            </Toolbar>
          </ToolbarStyle>
        </RootContainer>
      </AppBar>
      <ProductMultiplePrinterModal
        open={open}
        handleClose={() => setOpen(false)}
      />
    </>
  );
};

const ToolbarStyle = styled.div`
  .toolbar {
    display: flex;
    justify-content: space-between;
    background: #dedede;
  }

  .app-name {
    color: #375954;
    font-weight: 600;
    font-size: x-large;
  }

  .print-cart {
    background: #375954;
  }

  .logo-version-container {
    display: inline-flex;
    .logo {
      width: 150px;
    }
  }
`;
