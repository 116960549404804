import React from 'react';
import { Product } from '../../types';
import { GradeData } from './GradeData';
import styled from 'styled-components';
import { Tables } from './Tables';
import Logo from '../../assets/company-logo.png';
import { fileUrl } from '../../utils';

export interface SheetProps {
  customer: string;
  unitNumber: string;
  product: Product;
  imageXOffset: number;
  imageYOffset: number;
  imageScale: number;
}

export const Sheet: React.FC<SheetProps> = ({
  customer,
  unitNumber,
  product,
  imageXOffset,
  imageYOffset,
  imageScale,
}) => {
  const imageFile = fileUrl(product, 'imageFile');
  return (
    <SheetStyle>
      <div className="ss-header-bar">
        <img src={Logo} alt="Logo" style={{ height: 40 }} />
        <div style={{ marginLeft: 20 }}>Profile Specification Sheet</div>
      </div>

      <GradeData product={product} />

      {imageFile != null && (
        <img
          src={imageFile}
          alt="Product"
          className="ss-product-image"
          style={{
            right: `calc(130mm - ${imageXOffset}mm)`,
            bottom: `calc(100mm + ${imageYOffset}mm)`,
            transform: `scale(${imageScale})`,
          }}
        />
      )}

      <div className="ss-cust-unit-num">
        <span>Customer: {customer}</span>
        <span>Unit Number: {unitNumber}</span>
      </div>

      <Tables customer={customer}/>
    </SheetStyle>
  );
};

const SheetStyle = styled.div`
  display: grid !important;
  grid-template-areas:
    'header'
    'cust-unit'
    'data'
    'footer';
  .ss-product-image {
    position: absolute;
    max-width: 600px;
    max-height: 250px;
  }

  .ss-table {
    border-collapse: collapse;
    table-layout: auto !important;
    width: auto !important;
    & td,
    & th {
      border: 1px solid black;
      margin: 0;
      padding: 0;
      text-align: left;
      font-size: 0.8em;
      height: 4mm;
    }

    & th {
      padding: 0.2mm 1.5mm;
    }
  }
  .ss-tables {
    position: absolute;
    bottom: 20mm;
  }

  .ss-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    float: none;
    break-after: page;
    page-break-after: always;
  }
  .ss-header-bar {
    display: flex;
    align-items: center;
    height: 60px;
    grid-area: header;
    font-size: 2.3em;
    margin-bottom: 5mm;
  }
  .ss-cust-unit-num {
    grid-area: cust-unit;
    font-size: 1.1em;
    display: flex;
    justify-content: space-between;
  }

  #spec-sheet-footer {
    position: absolute;
    left: 50%;
    height: 15mm;
    transform: translate(-50%, 0);
    bottom: 7px;
  }
`;
