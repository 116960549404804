import LinearProgress from '@mui/material/LinearProgress';
import styled from 'styled-components';
import { ManageProduct } from './ManageProduct';
import { ProductDrawer } from './ProductDrawer';
import { Error } from '../Error';
import { Routes } from '../../constants';
import { useProductsQuery } from '../../generated/graphql';

export const ProductsAdmin: React.FC = () => {
  const { productId } = Routes.Products.useParams();

  const { loading, data, error } = useProductsQuery({
    pollInterval: 60000,
  });

  if (loading) return <LinearProgress />;
  if (error || !data?.parentProducts) return <Error />;

  return (
    <ProductsPageStyle>
      <ProductDrawer selectedId={productId} products={data.parentProducts} />
      {productId != null ? (
        <ManageProduct key={productId} productId={productId} />
      ) : (
        <h3 className="none-selected">Please select a product on the left</h3>
      )}
    </ProductsPageStyle>
  );
};

const ProductsPageStyle = styled.div`
  width: 100%;
  margin-left: 300px;

  .none-selected {
    text-align: center;
    margin-top: 100px;
  }
`;
