import { useRouteMatch } from 'react-router-dom';

export const Routes = {
  Home: {
    template: '/',
    path: () => '/',
  },
  Print: {
    template: '/print',
    path: () => '/print',
  },
  Customers: {
    template: '/customers/:customerId?',
    allPath: () => '/customers',
    singlePath: (customerId: ID) => `/customers/${customerId}`,
    useParams: () => {
      const { customerId } = useRouteMatch<{ customerId?: string }>().params;
      return { customerId: asId(customerId) };
    },
  },
  Products: {
    template: '/products/:productId?',
    allPath: () => '/products',
    singlePath: (productId: ID) => `/products/${productId}`,
    useParams: () => {
      const { productId } = useRouteMatch<{ productId?: string }>().params;
      return { productId: asId(productId) };
    },
  },
  SpecSheet: {
    template: '/new-spec-sheet/:customerId?/:productId?/:unitNumber?',
    path: (params?: SpecSheetParams) => {
      if (params == null) {
        return '/new-spec-sheet';
      }
      if ('unitNumber' in params) {
        return `/new-spec-sheet/${params.customerId}/${params.productId}/${params.unitNumber}`;
      }
      if ('productId' in params) {
        return `/new-spec-sheet/${params.customerId}/${params.productId}`;
      }
      return `/new-spec-sheet/${params.customerId}`;
    },
    useParams: (): SpecSheetParams => {
      const match = useRouteMatch<{
        customerId?: string;
        productId?: string;
        unitNumber?: string;
      }>().params;

      const customerId = asId(match.customerId);
      const productId = asId(match.productId);
      const unitNumber = match.unitNumber ?? null;

      if (unitNumber != null) {
        return {
          customerId: customerId!,
          productId: productId!,
          unitNumber: unitNumber!,
        };
      }

      if (productId != null) {
        return {
          customerId: customerId!,
          productId: productId!,
        };
      }

      if (customerId != null) {
        return { customerId };
      }

      return undefined;
    },
  },
};

type SpecSheetParamsFull = {
  customerId: ID;
  productId: ID;
  unitNumber: string;
};

type SpecSheetParams =
  | undefined
  | Pick<SpecSheetParamsFull, 'customerId'>
  | Pick<SpecSheetParamsFull, 'customerId' | 'productId'>
  | SpecSheetParamsFull;

function asId(str: string | null | undefined): ID | null {
  if (str == null) return null;
  return str as ID;
}
