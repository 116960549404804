import { version } from '../package.json';

const localhost = {
  profile: 'localhost',
  version,
  serverUri: 'https://staging-api.genia.co.nz/graphql', // <-- local or remote server as needed
  // serverUri: 'http://localhost:4000/graphql', // <-- local or remote server as needed
  AZURE_AD_CLIENT_ID: '510800e8-69a0-4e23-bb63-773dcf5947cf',
  AZURE_AD_TENANT_ID: 'b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4',
  AZURE_AD_API_IDENTIFIER: 'api://81345e5e-e3e3-42b9-b74c-85d2bc1f79da/user_impersonation'
};

const development = {
  profile: 'development',
  version,
  serverUri: 'https://staging-api.genia.co.nz/graphql',
  AZURE_AD_CLIENT_ID: '510800e8-69a0-4e23-bb63-773dcf5947cf',
  AZURE_AD_TENANT_ID: 'b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4',
  AZURE_AD_API_IDENTIFIER: 'api://81345e5e-e3e3-42b9-b74c-85d2bc1f79da/user_impersonation'
};

const staging = {
  profile: 'staging',
  version,
  serverUri: 'https://staging-api.genia.co.nz/graphql',
  AZURE_AD_CLIENT_ID: '510800e8-69a0-4e23-bb63-773dcf5947cf',
  AZURE_AD_TENANT_ID: 'b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4',
  AZURE_AD_API_IDENTIFIER: 'api://81345e5e-e3e3-42b9-b74c-85d2bc1f79da/user_impersonation'
};

const production = {
  profile: 'production',
  version,
  serverUri: 'https://api.genia.co.nz/graphql',
  AZURE_AD_CLIENT_ID: '7bee7698-8b83-4a6a-9d31-7b0b4151b2de',
  AZURE_AD_TENANT_ID: 'b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4',
  AZURE_AD_API_IDENTIFIER: 'api://2ed90395-b6ed-43ef-8632-d014b425d6b6/user_impersonation'
};

export const env = (() => {
  const url = window.location.href;
  if (url.includes('profile-sheets.dev01.genia.co.nz')) return development;
  if (url.includes('staging-profile-sheets.genia.co.nz')) return staging;
  if (url.includes('profile-sheets.genia.co.nz')) return production;
  return localhost;
})();

export const releaseProfile = () => env.profile || 'local';
