import styled from 'styled-components';
import { Navbar } from './Navbar';
import { PrintHide } from './PrintVisibility';

export const ContentWrapper: React.FC = ({ children }) => (
  <>
    <PrintHide>
      <Navbar />
    </PrintHide>
    <WrapperStyle>{children}</WrapperStyle>
  </>
);

const WrapperStyle = styled.div`
  margin-top: 64px;
  height: calc(100vh - 64px);
  overflow-y: auto;
  display: flex;
  justify-content: center;
`;
