import { List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { Routes } from '../../constants';
import { CustomerPreview } from '../../types';
import { useHistory } from 'react-router-dom';

interface CustomerDrawerContentsProps {
  elements: CustomerPreview[];
  selectedId: ID | null;
  specSheet?: boolean;
}

export const CustomerDrawerContents: React.FC<CustomerDrawerContentsProps> = ({
  selectedId,
  elements,
  specSheet,
}) => {
  const history = useHistory();
  return (
    <List className="list">
      {elements.length === 0 && (
        <ListItem style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography>
            Nothing to see here...{' '}
            <span role="img" aria-label="nice">
              👌
            </span>
          </Typography>
        </ListItem>
      )}
      {elements.map((elem) => (
        <ListItem
          button
          key={elem.id}
          onClick={() => {
            if (specSheet) {
              history.push(Routes.SpecSheet.path({ customerId: elem.id }));
              return;
            }

            history.push(
              selectedId === elem.id
                ? Routes.Customers.allPath()
                : Routes.Customers.singlePath(elem.id),
            );
          }}
          style={{
            height: 60,
            backgroundColor: elem.id === selectedId ? '#eee' : undefined,
          }}
        >
          <Typography>{elem.name || '-'}</Typography>
        </ListItem>
      ))}
    </List>
  );
};
