
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "FileInfo": [
      "DeletedFile",
      "PresentFile"
    ],
    "ProductProfileCreateError": [
      "ProductProfileCreateInvalidNestingError",
      "ProductProfileCreateNotFoundError"
    ],
    "ProductProfileDuplicateError": [
      "ProductProfileDuplicateInvalidNestingError",
      "ProductProfileDuplicateNotFoundError"
    ],
    "ProductProfileUpdateError": [
      "ProductProfileUpdateExcessivePayloadError",
      "ProductProfileUpdateNotFoundError"
    ],
    "ProductProfileUpdateImagePlacementsError": [
      "ProductProfileUpdateImagePlacementsNotFoundError"
    ],
    "ProductProfileUploadImageError": [
      "ProductProfileUploadImageNotFoundError"
    ],
    "ProductProfileUploadTechnicalFileError": [
      "ProductProfileUploadTechnicalFileNotFoundError"
    ],
    "ProfileCustomerUpdateError": [
      "ProfileCustomerUpdateNotFoundError"
    ],
    "UpdateHandlesGetError": [
      "UpdateHandlesGetNotFoundError",
      "UpdateHandlesGetNotModifiableError"
    ],
    "UploadHandlesGetError": [
      "UploadHandlesGetInvalidInputError"
    ],
    "UserError": [
      "ProductProfileCreateInvalidNestingError",
      "ProductProfileCreateNotFoundError",
      "ProductProfileDuplicateInvalidNestingError",
      "ProductProfileDuplicateNotFoundError",
      "ProductProfileUpdateExcessivePayloadError",
      "ProductProfileUpdateImagePlacementsNotFoundError",
      "ProductProfileUpdateNotFoundError",
      "ProductProfileUploadImageNotFoundError",
      "ProductProfileUploadTechnicalFileNotFoundError",
      "ProfileCustomerUpdateNotFoundError",
      "UpdateHandlesGetNotFoundError",
      "UpdateHandlesGetNotModifiableError",
      "UploadHandlesGetInvalidInputError"
    ]
  }
};
      export default result;
    