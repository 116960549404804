import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import { useUploadProductImageFileMutation } from '../../generated/graphql';
import { displayUserErrors } from '../../errors';
import axios from 'axios';

export interface ProductImageProps {
  productId: ID;
  imageUrl?: string;
  onUploaded: () => void;
}

export const ProductImage: React.FC<ProductImageProps> = ({
  productId,
  imageUrl,
  onUploaded,
}) => {
  const [cacheCounter, setCacheCounter] = useState(0);
  const [getHandle] = useUploadProductImageFileMutation({
    variables: {
      input: { productId },
    },
  });

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <ProductImageStyle>
      <div className="image-container">
        <div className="header-container">
          <h3 className="header">Image</h3>
          <form ref={formRef}>
            <Button
              className="button"
              component="label"
              onChange={async (event: any) => {
                if (formRef.current == null || !event.target?.validity.valid) {
                  return;
                }

                const file = event.target.files[0];
                formRef.current.reset();

                let url: string | null = null;
                const mutation = getHandle().then((x) => {
                  url = x.data?.result.uploadUrl ?? null;
                  return x;
                });

                await displayUserErrors('result', mutation, true);

                if (url == null) {
                  return;
                }

                await axios.put(url, file, {
                  headers: {
                    'x-ms-date': new Date().toISOString(),
                    'x-ms-version': '2020-12-06',
                    'x-ms-blob-type': 'BlockBlob',
                  },
                });

                setCacheCounter((c) => c + 1);
                onUploaded();
              }}
              startIcon={<AddCircleIcon />}
              variant={'contained'}
              disableElevation
            >
              Upload Image
              <input type="file" accept=".png, .jpg" hidden />
            </Button>
          </form>
        </div>
        {imageUrl && (
          <img
            src={`${imageUrl}&__genia_cache=${cacheCounter}`}
            className="image"
            alt="Product"
          />
        )}
      </div>
    </ProductImageStyle>
  );
};

const ProductImageStyle = styled.div`
  .image {
    max-width: 450px;
    max-height: 450px;
    margin-bottom: 20px;
    display: flex;
    place-content: center;
  }
`;
