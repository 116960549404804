import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ExtraRequirement, Product, ProductState } from '../../types';
import { Error } from '../Error';
import Cross from '@mui/icons-material/Clear';
import { ProductMultiplePrinterModal } from '../ProductMultiplePrinterModal';
import dateFormat from 'dateformat';

import {
  fileUrl,
  invalidStacking,
  isProductUnchanged,
  mm2ToLmPerM3,
  reorder,
  revert,
} from '../../utils';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import { CustomerSpecificModal } from './CustomerSpecificModal';
import { DropResult } from 'react-beautiful-dnd';
import { ExtraRequirementList } from './ExtraRequirementList';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AddExtraRequirementsModal } from './AddExtraRequirementModal';
import { ProductImage } from './ProductImage';
import Divider from '@mui/material/Divider';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  LinearProgress,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../constants';
import PrintIcon from '@mui/icons-material/Print';
import { useDispatch } from 'react-redux';
import { addToPrint, clearPrintList } from '../../redux/store';
import { TechnicalFile } from './TechnicalFile';
import {
  useCustomersQuery,
  useDeleteProductMutation,
  useDuplicateProductMutation,
  useProductQuery,
  useUpdateProductMutation,
} from '../../generated/graphql';
import { Prompt } from '../Prompt';
import { Queries } from '../../queries/queries';
import { displayUserErrors } from '../../errors';

export interface ManageProductProps {
  productId: ID;
}

export const ManageProduct: React.FC<ManageProductProps> = ({ productId }) => {
  const { loading, data, error, refetch } = useProductQuery({
    variables: { id: productId },
  });

  if (loading) return <LinearProgress />;
  if (error || !data?.product) return <Error />;

  return (
    <ManageProductInner
      key={productId}
      productId={productId}
      productApi={data.product}
      refetch={refetch}
    />
  );
};

interface ManageProductInnerProps {
  productId: ID;
  productApi: Product;
  refetch: () => void;
}

const ManageProductInner: React.FC<ManageProductInnerProps> = ({
  productId,
  productApi,
  refetch,
}) => {
  const customers = useCustomersQuery();

  const {
    extraRequirements,
    addRequirement,
    removeRequirement,
    reorderRequirements,
    fields,
    setField,
    revertToSaved,
  } = useProductState(productApi, productApi.extraRequirements);

  type Modal = 'customers' | 'requirements' | 'quick-print' | 'none';
  const [modal, setModal] = useState<Modal>('none');

  const history = useHistory();
  const dispatch = useDispatch();

  const [remove, { loading: deleting }] = useDeleteProductMutation();
  const [save, { loading: saving }] = useUpdateProductMutation();
  const [duplicate, { loading: duplicating }] = useDuplicateProductMutation();

  const mutationLoading = deleting || saving || duplicating;

  const changed = useMemo(() => {
    return (
      !isProductUnchanged(fields, productApi, extraRequirements) &&
      !invalidStacking(
        fields.stackingWidth,
        fields.stackingHeight,
        fields?.stackingSubpack,
      )
    );
  }, [extraRequirements, productApi, fields]);

  const saveProduct = useCallback(() => {
    const mutation = save({
      variables: {
        input: {
          productId,
          name: fields.name,
          notes: fields.notes,
          gradeSpec: fields.gradeSpec,
          machineSpec: fields.machineSpec,
          sizeTolerance: fields.sizeTolerance,
          endUsage: fields.endUsage,
          lengthSpec: fields.lengthSpec,
          ourCode: fields.ourCode,
          stacking: fields.stackingWidth
            ? {
                packWidth: parseInt(fields.stackingWidth, 10),
                packHeight: parseInt(fields.stackingHeight!, 10),
                subpack: fields.stackingSubpack,
              }
            : null,
          assignedCustomerId: fields.assignedCustomerId,
          customerReference: fields.customerReference,
          mm2:
            fields.mm2 === '' || fields.mm2 == null
              ? null
              : parseFloat(fields.mm2),
          extraRequirements: extraRequirements.map((req) => ({
            key: req.key,
            value: req.value,
          })),
        },
      },
    });

    displayUserErrors('result', mutation, true);
  }, [save, fields, extraRequirements, productId]);

  const onDragEnd = ({ destination, source }: DropResult) => {
    // Dropped outside the list
    if (!destination) return;
    reorderRequirements(source.index, destination.index);
  };

  const handleChangeCustomer = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setField('assignedCustomerId', event.target.value as ID);
  };

  const handleQuickPrint = () => {
    dispatch(clearPrintList());
    dispatch(addToPrint(productApi));
    setModal('quick-print');
  };

  const handleDelete = () => {
    const mutation = remove({
      variables: {
        input: { productId },
      },
      refetchQueries: [Queries.Products],
      awaitRefetchQueries: true,
    }).then((result) => {
      history.push(Routes.Products.allPath());
      return result;
    });

    displayUserErrors('result', mutation, true);
  };

  const handleDuplicate = (newChild: boolean) => {
    const mutation = duplicate({
      variables: {
        input: {
          productId,
          child: newChild,
        },
      },
      refetchQueries: [Queries.Products],
      awaitRefetchQueries: true,
    }).then((result) => {
      const product = result.data?.result.newProduct?.id;
      if (newChild && product != null) {
        history.push(Routes.Products.singlePath(product));
      }
      return result;
    });

    displayUserErrors('result', mutation, true);
  };

  return (
    <>
      <Prompt
        when={changed}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <ManageProductStyle>
        <div className="action-buttons">
          <Button
            className="delete"
            variant="contained"
            disabled={mutationLoading}
            onClick={handleDelete}
          >
            {mutationLoading ? '...' : 'Delete'}
          </Button>
          <Button
            disabled={changed || mutationLoading}
            className="duplicate"
            variant="contained"
            onClick={() => {
              handleDuplicate(false);
            }}
          >
            {mutationLoading ? '...' : 'Duplicate'}
          </Button>
          <Button
            disabled={fields && productApi && !changed}
            className="revert"
            variant="contained"
            onClick={revertToSaved}
          >
            REVERT
          </Button>

          <Button
            disabled={(fields && productApi && !changed) || mutationLoading}
            style={{
              background:
                fields && productApi && !changed ? '#979b9a' : '#375954',
            }}
            className="save"
            variant="contained"
            color="primary"
            onClick={() => saveProduct()}
          >
            {mutationLoading ? '...' : 'SAVE'}
          </Button>

          <Button
            startIcon={<AddIcon />}
            variant={'contained'}
            className="add-print-button"
            disableElevation
            size="large"
            color={'secondary'}
            onClick={() => dispatch(addToPrint(productApi))}
          >
            Add to Print
          </Button>
          <Button
            startIcon={<PrintIcon />}
            variant={'contained'}
            className="add-print-button quick"
            disableElevation
            size="large"
            color={'secondary'}
            onClick={() => handleQuickPrint()}
          >
            Quick Print
          </Button>
        </div>
        <div className="product-header">
          <div className="container">
            <h1>
              {productApi.name !== '' ? productApi.name : 'Unknown Product'}
            </h1>
            {productApi.lastUpdated && (
              <div className="updated">
                Last Updated:{' '}
                {dateFormat(
                  new Date(productApi.lastUpdated),
                  'yyyy-mm-dd h:MM:ss TT',
                )}
              </div>
            )}
          </div>
          <div className="customer-buttons">
            {productApi.parent != null && (
              <Button
                startIcon={<VisibilityIcon />}
                className="view-parent"
                size="large"
                variant="contained"
                onClick={() => {
                  history.push(
                    Routes.Products.singlePath(productApi.parent!.id),
                  );
                }}
              >
                View Parent Product
              </Button>
            )}
            {productApi.children.length > 0 && (
              <Button
                startIcon={<VisibilityIcon />}
                className="view-customer"
                size="large"
                variant="contained"
                onClick={() => setModal('customers')}
              >
                Customer Products
              </Button>
            )}
            {productApi.parent == null && (
              <Button
                startIcon={<AddIcon />}
                className="add-customer"
                size="large"
                variant="contained"
                onClick={() => handleDuplicate(true)}
                disabled={changed}
              >
                Assign Product
              </Button>
            )}
          </div>
        </div>

        <TextField
          className="input"
          id="filled-basic"
          label="Adjust Name"
          variant="filled"
          autoFocus
          value={fields?.name}
          onChange={(e) => setField('name', e.target.value)}
        />
        {productApi.parent != null && (
          <div className="customer-specific">
            <h3 className="heading">Customer Information</h3>
            <FormControl className="customer-select">
              <InputLabel id="demo-simple-select-label" variant={'filled'}>
                Assigned Customer
              </InputLabel>
              <Select
                className="select"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fields?.assignedCustomerId ?? ''}
                onChange={(e: any) => handleChangeCustomer(e)}
              >
                {(customers.data?.customers ?? []).map((x) => (
                  <MenuItem value={x.id} key={x.id}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              className="input"
              id="filled-basic"
              label="Customer Reference"
              variant="filled"
              value={fields?.customerReference ?? ''}
              onChange={(e) => setField('customerReference', e.target.value)}
            />
          </div>
        )}
        <h3 className="heading">Pack Dimensions</h3>
        <div className="stacking">
          <div className="dimensions">
            <TextField
              className="input stack-width"
              id="filled-basic"
              label="Width"
              type="number"
              variant="filled"
              value={fields?.stackingWidth ?? ''}
              onChange={(e) => setField('stackingWidth', e.target.value)}
            />
            <Cross className="x" />
            <TextField
              className="input stack-height"
              id="filled-basic"
              label="Height"
              type="number"
              variant="filled"
              value={fields?.stackingHeight ?? ''}
              onChange={(e) => setField('stackingHeight', e.target.value)}
            />
          </div>

          <TextField
            className="input"
            id="filled-basic"
            label="Subpack"
            variant="filled"
            value={fields?.stackingSubpack ?? ''}
            onChange={(e) => setField('stackingSubpack', e.target.value)}
          />

          <TextField
            className="input"
            id="filled-textarea"
            label="Square mm"
            type="number"
            variant="filled"
            value={fields?.mm2}
            onChange={(e) => {
              // Restrict to 2dp
              if ((e.target.value.split('.')[1] ?? '').length > 2) return;
              setField('mm2', e.target.value);
            }}
          />
          <div className="LM">
            <h3>LM per m³</h3>
            <span className="value">{mm2ToLmPerM3(productApi.mm2)}</span>
          </div>
        </div>
        <h3 className="heading">Specifications</h3>
        <div className="specs-container">
          <TextField
            className="input specs"
            id="filled-textarea"
            label="Grade Spec"
            multiline
            variant="filled"
            value={fields?.gradeSpec}
            onChange={(e) => setField('gradeSpec', e.target.value)}
          />
          <TextField
            className="input specs"
            id="filled-textarea"
            label="Machine Spec"
            multiline
            variant="filled"
            value={fields?.machineSpec}
            onChange={(e) => setField('machineSpec', e.target.value)}
          />

          <TextField
            className="input specs"
            id="filled-textarea"
            label="Length Spec"
            multiline
            variant="filled"
            value={fields?.lengthSpec}
            onChange={(e) => setField('lengthSpec', e.target.value)}
          />
        </div>
        <div className="tolerance-usage">
          <TextField
            className="input tolerance"
            id="filled-textarea"
            label="Size Tolerance"
            multiline
            variant="filled"
            value={fields?.sizeTolerance}
            onChange={(e) => setField('sizeTolerance', e.target.value)}
          />
          <div className="usage-code">
            <TextField
              className="input usage"
              id="filled-textarea"
              label="End Usage"
              multiline
              variant="filled"
              value={fields?.endUsage}
              onChange={(e) => setField('endUsage', e.target.value)}
            />
            <TextField
              className="input code"
              id="filled-textarea"
              label="Genia Code"
              multiline
              variant="filled"
              value={fields?.ourCode}
              onChange={(e) => setField('ourCode', e.target.value)}
            />
          </div>
        </div>

        <TextField
          className="input"
          id="filled-textarea"
          label="Notes (not printed)"
          minRows={4}
          multiline
          variant="filled"
          value={fields?.notes}
          onChange={(e) => setField('notes', e.target.value)}
        />
        <Divider className="divider" />

        <ProductImage
          productId={productId}
          imageUrl={fileUrl(productApi, 'imageFile')}
          onUploaded={refetch}
        />

        <Divider className="divider" />
        <TechnicalFile
          productId={productId}
          technicalFileName={productApi.technicalFile?.name ?? undefined}
          technicalFileUrl={fileUrl(productApi, 'technicalFile')}
          onUploaded={refetch}
        />

        <Divider className="divider" />
        <div className="extra-requirements">
          <h3 className="header">Extra Requirements</h3>
          <Button
            className="button"
            onClick={() => setModal('requirements')}
            startIcon={<AddCircleIcon />}
            variant={'contained'}
            disableElevation
          >
            New Requirement
          </Button>
        </div>

        <ExtraRequirementList
          onDragEnd={onDragEnd}
          remove={removeRequirement}
          items={extraRequirements}
        />
      </ManageProductStyle>
      <CustomerSpecificModal
        open={modal === 'customers'}
        handleClose={() => setModal('none')}
        childProducts={productApi.children}
      />
      <AddExtraRequirementsModal
        create={addRequirement}
        open={modal === 'requirements'}
        handleClose={() => setModal('none')}
      />

      <ProductMultiplePrinterModal
        open={modal === 'quick-print'}
        handleClose={() => setModal('none')}
        customerSelected={fields.assignedCustomerId}
      />
    </>
  );
};

function useProductState(
  initial: Product,
  initialRequirements: ExtraRequirement[],
) {
  const [extraRequirements, setRequirements] = useState(initialRequirements);

  const addRequirement = (x: ExtraRequirement) => {
    setRequirements((prev) => [...prev, x]);
  };

  // Can't remove based on ID as new ones will not be given a client-side ID
  const removeRequirement = (x: ExtraRequirement) => {
    setRequirements((prev) => [
      ...prev.filter((prev) => prev.value !== x.value && prev.key !== x.key),
    ]);
  };

  const reorderRequirements = (source: number, destination: number) => {
    const orderedList = reorder(extraRequirements, source, destination);
    setRequirements(orderedList);
  };

  const [fields, setProduct] = useState<ProductState>(() => revert(initial));

  const setField = <Key extends keyof ProductState>(
    field: Key,
    value: ProductState[Key],
  ) => {
    setProduct((p) => ({
      ...p,
      [field]: value,
    }));
  };

  const revertToSaved = () => {
    setProduct(revert(initial));
    setRequirements(initialRequirements);
  };

  return {
    extraRequirements,
    addRequirement,
    removeRequirement,
    reorderRequirements,
    fields,
    setField,
    revertToSaved,
  };
}

const ManageProductStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  margin-top: 42px;
  padding-bottom: 80px;

  .action-buttons {
    margin-bottom: 50px;
    .save {
      max-height: 40px;
    }
    .revert {
      margin: 0px 20px;
    }
    .delete {
      background: #bc2f5e;
      color: white;
    }
    .duplicate {
      margin-left: 20px;
    }
    .quick {
      margin-right: 10px;
    }
    .add-print-button {
      float: right;
      background: #375954;
    }
  }

  .customer-specific {
    .heading {
      margin-bottom: 16px;
    }
    .customer-select {
      min-width: 250px;
      margin-right: 50px;
      .select {
        padding: 12px;
      }
    }
  }
  .divider {
    margin-top: 20px;
  }
  .button {
    background: #375954;
  }

  .image-container,
  .technical-file {
    .header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 40px 0px 30px 0px;
    }
  }

  .extra-requirements {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 40px 0px;
  }

  .product-header {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    .customer-buttons {
      display: flex;
      flex-direction: column;
      .add-customer {
        margin-top: 10px;
      }
    }
    .view-parent {
      background: #375954;
      max-height: 42px;
      margin-right: 10px;
    }
    .container {
      .updated {
        margin-top: 20px;
        font-size: larger;
      }
    }
  }

  .heading {
    margin-top: 15px;
  }
  .input {
    margin: 1rem 0;
  }
  .stacking {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .LM {
      align-self: center;
      border-radius: 3px;
      padding: 8px;
      .value {
        font-size: larger;
        font-weight: 500;
      }
    }

    .dimensions {
      display: flex;
      align-items: center;
      flex-direction: row;

      .x {
        margin: 0 10px;
      }

      .stack-width,
      .stack-height {
        width: 80px;
      }
    }
  }

  .tolerance,
  .usage {
    width: 100%;
  }

  .specs {
    width: 100%;
  }

  .usage-code {
    display: inline-flex;
    .code {
      margin-left: 50px;
    }
  }

  .products {
    display: flex;
    flex-direction: column;
  }
  .product {
    margin: 10px 0;
    inline-size: fit-content;
  }
`;
