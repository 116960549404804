import { Route, Switch } from 'react-router-dom';
import { ContentWrapper } from './ContentWrapper';
import { Error } from './Error';
import { Routes } from '../constants';
import { CustomersAdmin } from './customers/CustomersAdmin';
import { HomePage } from './HomePage';
import { SpecSheetAdmin } from './specsheet/SpecSheetAdmin';
import { ProductsAdmin } from './products/ProductsAdmin';
import { PrintPage } from './PrintPage';
import { withSentryRouting } from '@sentry/react';

const SentryRoute = withSentryRouting(Route);

export const Router: React.FC = () => (
  <ContentWrapper>
    <Switch>
      <SentryRoute
        exact
        path={Routes.Products.template}
        component={ProductsAdmin}
      />
      <SentryRoute
        exact
        path={Routes.Customers.template}
        component={CustomersAdmin}
      />
      <SentryRoute
        exact
        path={Routes.SpecSheet.template}
        component={SpecSheetAdmin}
      />
      <SentryRoute exact path={Routes.Print.template} component={PrintPage} />
      <SentryRoute exact path={Routes.Home.template} component={HomePage} />
      <SentryRoute path="*" exact>
        <Error errorText="404. Hmm" />
      </SentryRoute>
    </Switch>
  </ContentWrapper>
);
