import * as msal from '@azure/msal-browser';
import { env, releaseProfile } from '../runtime-environment';
import { store } from '../redux/store';

export const Scopes = {
  GENIA_API: env.AZURE_AD_API_IDENTIFIER,
};

const config: msal.Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${env.AZURE_AD_TENANT_ID}`,
    clientId: env.AZURE_AD_CLIENT_ID,
    // The configured reply url
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
    secureCookies: true,
  },
};

let provider: msal.PublicClientApplication | null = null;
export const getProvider = (): msal.PublicClientApplication => {
  if (provider) return provider;

  provider = new msal.PublicClientApplication(config);

  provider.addEventCallback((message) => {
    if (message.eventType === msal.EventType.LOGOUT_SUCCESS) {
      store.dispatch({ type: 'AAD_LOGOUT_SUCCESS' });
    }
  });

  return provider;
};

export async function getBackendToken(): Promise<string | null> {
  if (authDisabled) return Promise.resolve(null);
  const provider = getProvider();
  return provider
    .acquireTokenSilent({
      scopes: [Scopes.GENIA_API],
      account: provider.getAllAccounts()[0],
    })
    .then((result) => result.accessToken)
    .catch(async (error) => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        return await provider
          .acquireTokenPopup({
            scopes: [Scopes.GENIA_API],
            account: provider.getAllAccounts()[0],
          })
          .then((result) => result.accessToken);
      }

      throw error;
    });
}

export const authenticationRequest: msal.RedirectRequest = {
  scopes: [Scopes.GENIA_API],
};

// Can flick to `true` for development purposes, if/when desired.
export const authDisabled = releaseProfile() === 'local';
