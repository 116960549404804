import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import TextField from '@mui/material/TextField';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Routes } from '../../constants';
import { Error } from '../Error';
import { Prompt } from '../Prompt';
import * as Sentry from '@sentry/react';
import {
  ProfileCustomer,
  useCustomerQuery,
  useDeleteCustomerMutation,
  useUpdateCustomerMutation,
} from '../../generated/graphql';
import { displayUserErrors } from '../../errors';
import { Queries } from '../../queries/queries';

export interface ManageCustomerProps {
  customerId: ID;
}

function isUnchanged(
  name: string,
  notes: string,
  saved: Pick<ProfileCustomer, 'name' | 'notes'>,
): boolean {
  return name === saved.name && notes === saved.notes;
}

export const ManageCustomer: React.FC<ManageCustomerProps> = ({
  customerId,
}) => {
  const [nameOverride, setName] = useState<string>();
  const [notesOverride, setNotes] = useState<string>();

  const history = useHistory();

  const { loading, data, error } = useCustomerQuery({
    variables: { id: customerId },
  });

  if (error) {
    Sentry.captureMessage('Could not load customers', Sentry.Severity.Error);
  }

  const [update, { loading: saving }] = useUpdateCustomerMutation();
  const [remove, { loading: deleting }] = useDeleteCustomerMutation();

  if (loading) return <LinearProgress />;
  if (error || !data?.customer) return <Error />;

  const customerApi = data.customer;

  const handleDelete = () => {
    const mutation = remove({
      variables: {
        input: { id: customerId },
      },
      refetchQueries: [Queries.Customers],
      awaitRefetchQueries: true,
    }).then((result) => {
      history.push(Routes.Customers.allPath());
      return result;
    });

    displayUserErrors('result', mutation, true);
  };

  const revert = () => {
    setName(undefined);
    setNotes(undefined);
  };

  const name = nameOverride ?? customerApi.name;
  const notes = notesOverride ?? customerApi.notes;

  const saveCustomer = () => {
    const mutation = update({
      variables: {
        input: {
          id: customerId,
          name,
          notes,
        },
      },
    });

    displayUserErrors('result', mutation, true);
  };

  const changed = !isUnchanged(name, notes, customerApi);

  return (
    <>
      <Prompt
        when={changed}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <ManageCustomerStyle>
        <div className="action-buttons">
          <Button
            className="delete"
            variant="contained"
            disabled={deleting}
            onClick={() => {
              handleDelete();
            }}
          >
            {deleting ? '...' : 'Delete'}
          </Button>
          <Button
            disabled={!changed}
            className="revert"
            variant="contained"
            onClick={() => revert()}
          >
            REVERT
          </Button>
          <Button
            disabled={!changed}
            style={{ background: changed ? '#375954' : '#979b9a' }}
            className="save"
            variant="contained"
            color="primary"
            onClick={() => saveCustomer()}
          >
            {saving ? '...' : 'SAVE'}
          </Button>
        </div>
        <h1 className="header">{customerApi.name}</h1>

        <TextField
          className="name-input"
          id="filled-basic"
          label="Name"
          variant="filled"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          className="notes-input"
          id="filled-textarea"
          label="Notes (not printed)"
          multiline
          variant="filled"
          minRows={10}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        {customerApi.assignedProducts.length > 0 && (
          <div className="products">
            <h3 className="header">Customer Specific Products</h3>
            {customerApi.assignedProducts.map((product) => (
              <Button
                key={product.id}
                className="product"
                onClick={() => {
                  history.push(Routes.Products.singlePath(product.id));
                }}
              >
                {product.name}
              </Button>
            ))}
          </div>
        )}
      </ManageCustomerStyle>
    </>
  );
};

const ManageCustomerStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  margin-top: 42px;
  .header {
    margin-bottom: 30px;
  }
  .action-buttons {
    margin-bottom: 50px;
    .save {
      max-height: 40px;
    }
    .revert {
      margin: 0px 20px;
    }
    .delete {
      background: #bc2f5e;
      color: white;
    }
  }
  .notes-input {
    margin: 2rem 0;
  }

  .products {
    display: flex;
    flex-direction: column;
  }
  .product {
    margin: 10px 0;
    inline-size: fit-content;
  }
`;
