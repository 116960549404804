import { Drawer, LinearProgress, Typography, Input } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import * as Sentry from '@sentry/react';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { Error } from '../Error';
import React, { useState } from 'react';
import { CustomerDrawerContents } from './CustomerDrawerContents';
import { applySearchQuery } from '../../utils';
import { CreateCustomerModal } from './CreateCustomerModal';
import { CustomerPreview } from '../../types';

export type ViewType = 'print' | 'manage';

interface CustomerDrawerProps {
  selectedId: ID | null;
  isLoading: boolean;
  error: any;
  customers: CustomerPreview[];
  specSheet?: boolean;
}

export const CustomerDrawer: React.FC<CustomerDrawerProps> = ({
  selectedId,
  isLoading,
  error,
  customers,
  specSheet,
}) => {
  const [filter, setFilter] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const filteredCustomers = applySearchQuery(customers, filter);

  let contents: React.ReactNode;
  if (isLoading) {
    contents = <LinearProgress />;
  } else if (error || !customers) {
    Sentry.captureMessage('Could not load customers', Sentry.Severity.Error);
    contents = <Error />;
  } else {
    contents = (
      <CustomerDrawerContents
        elements={filteredCustomers}
        selectedId={selectedId}
        specSheet={specSheet}
      />
    );
  }

  return (
    <>
      <CustomerDrawerStyle
        variant="persistent"
        anchor="left"
        open
        classes={{ paper: 'paper' }}
      >
        <div className="header">
          <Typography variant="h5">Customers</Typography>
          <Button
            startIcon={<AddCircleIcon />}
            variant="contained"
            disableElevation
            size="large"
            onClick={() => setOpenModal(true)}
          >
            New Customer
          </Button>
        </div>

        <Input
          className="search"
          onChange={(e) => setFilter(e.target.value)}
          value={filter}
          placeholder="Search"
        />
        {contents}
      </CustomerDrawerStyle>
      <CreateCustomerModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
    </>
  );
};

const CustomerDrawerStyle = styled(Drawer)`
  .header {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 1em;
    justify-content: space-between;
  }
  .paper {
    z-index: 30;
    position: fixed;
    top: 64px;
    height: calc(100vh - 64px);

    width: 100%;
    @media (min-width: 600px) {
      width: 350px;
    }
  }

  .search {
    background: none;
    margin: 20px 10px;
  }

  .list {
    overflow-y: auto;
    flex-grow: 1;
  }
`;
