import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import * as Integrations from '@sentry/integrations';
import './global.css';
import 'sweetalert2/dist/sweetalert2.css';
import App from './App';
import { Error } from './components/Error';
import { history } from './history';
import { env, releaseProfile } from './runtime-environment';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://955313e096964f11992a853af94bc8a7@o925760.ingest.sentry.io/6035629',
    release: `profile-sheets-client@${env.version}`,
    environment: releaseProfile(),
    normalizeDepth: 10,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', '.genia.co.nz'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Integrations.CaptureConsole({
        levels: ['warn', 'error'],
      }),
      new Integrations.Offline(),
    ],
    // We don't have millions of users, better safe than sorry
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      fallback={<Error fullscreen errorText="Uh-oh. The app crashed." />}
      showDialog
    >
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
);
