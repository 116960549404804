import { Drawer, LinearProgress, Typography } from '@mui/material';
import styled from 'styled-components';
import { Error } from '../Error';
import React from 'react';
import { SpecSheetDrawerContents } from './SpecSheetDrawerContents';
import { useProductQuery } from '../../generated/graphql';

interface SpecSheetDrawerProps {
  customerName: string;
  productId: ID;
}

export const SpecSheetDrawer: React.FC<SpecSheetDrawerProps> = ({
  customerName,
  productId,
}) => {
  const { loading, data, error } = useProductQuery({
    variables: {
      id: productId,
    },
  });

  let contents = null;
  if (loading) contents = <LinearProgress />;
  else if (error || !data?.product) contents = <Error />;
  else {
    contents = (
      <SpecSheetDrawerContents
        customerName={customerName}
        productData={data.product}
      />
    );
  }

  return (
    <>
      <SpecSheetDrawerStyle
        variant="persistent"
        anchor="left"
        open
        classes={{ paper: 'paper' }}
      >
        <div className="header">
          <Typography variant="h5">Spec Sheet</Typography>
        </div>

        {contents}
      </SpecSheetDrawerStyle>
    </>
  );
};

const SpecSheetDrawerStyle = styled(Drawer)`
  .header {
    margin-top: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 1em;
    justify-content: space-between;
  }
  .paper {
    z-index: 30;
    position: fixed;
    top: 64px;
    height: calc(100vh - 64px);

    width: 100%;
    @media (min-width: 600px) {
      width: 375px;
    }
  }

  .search {
    background: none;
    margin: 20px 10px;
  }

  .list {
    overflow-y: auto;
    flex-grow: 1;
  }
`;
