import {
  DialogTitle,
  DialogContent,
  TextField,
  Select,
  FormControl,
  List,
  ListItem,
  MenuItem,
  Typography,
  IconButton,
  Dialog,
  Button,
} from '@mui/material';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  addCustomer,
  addUnitNumber,
  removeFromPrint,
  selectProducts,
} from '../redux/store';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Routes } from '../constants';
import { useCustomersQuery } from '../generated/graphql';

export interface ProductMultiplePrinterModalProps {
  open: boolean;
  handleClose: () => void;
  customerSelected?: ID;
}

export const ProductMultiplePrinterModal: React.FC<
  ProductMultiplePrinterModalProps
> = ({ open, handleClose, customerSelected }) => {
  const { data } = useCustomersQuery();
  const customers = data?.customers || [];

  const dispatch = useDispatch();
  const [unitNumber, setUnitNumber] = useState('');
  const [customer, setCustomer] = useState(customerSelected ||'');
  const history = useHistory();
  const handlePrintAllItems = () => {
    if (customer == null) return;
    const cust = customers.find((x) => x.id === customer)!;
    dispatch(addUnitNumber(unitNumber));
    dispatch(addCustomer({ id: cust.id, name: cust.name }));
    history.push(Routes.Print.path());
    handleClose();
  };
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCustomer(event.target.value as string);
  };

  const addedProducts = useSelector(selectProducts);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <h2>Items to Print</h2>
      </DialogTitle>
      <DialogContent>
        <PrinterModalStyle>
          {addedProducts.length > 0 ? (
            <>
              <List className="list">
                {addedProducts.map((product, index: number) => (
                  <ListItem key={product.id} className="list-item">
                    <Typography>{product.name}</Typography>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => dispatch(removeFromPrint(index))}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>

              <div className="inputs">
                <FormControl className="customer-select">
                  <h2>Customer</h2>
                  <Select
                    className="field select"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={customer || customerSelected}
                    placeholder="Customer"
                    onChange={(e: any) => handleChange(e)}
                  >
                    {customers?.map((x) => (
                      <MenuItem key={x.id} value={x.id}>
                        {x.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <h2>Unit Number</h2>
                <TextField
                  className="field unit"
                  value={unitNumber}
                  autoFocus
                  onChange={(e) => setUnitNumber(e.target.value)}
                />
              </div>

              <Button
                className="print-all"
                onClick={() => handlePrintAllItems()}
                variant="contained"
                size="large"
                disabled={(!customer && !customerSelected) || !unitNumber}
              >
                Print All Items
              </Button>
            </>
          ) : (
            <h2>No products currently added</h2>
          )}
        </PrinterModalStyle>
      </DialogContent>
    </Dialog>
  );
};

const PrinterModalStyle = styled.div`
  min-height: 420px;
  min-width: 400px;
  .inputs {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: auto;

    h2 {
      margin-top: 20px;
      margin-bottom: 7px;
    }

    .field {
      font-size: large;
      color: #375954;
      height: 40px;
    }
    .unit {
    }
  }

  .list-item {
    justify-content: space-between;
  }

  .print-all {
    margin: 50px auto;
    display: block;
  }
`;
