import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Sheet } from './Sheet';
import PrintIcon from '@mui/icons-material/Print';
import { PrintRoot } from '../PrintVisibility';
import { Routes } from '../../constants';
import { useProductQuery } from '../../generated/graphql';
import { useUpdateServerImagePlacements } from '../../utils';
import { ProfileImagePlacement } from '../../types';

export interface ManageSpecSheetProps {
  customerName: string;
}

export const ManageSpecSheet: React.FC<ManageSpecSheetProps> = ({
  customerName,
}) => {
  const params = Routes.SpecSheet.useParams();

  if (params == null || !('unitNumber' in params)) {
    throw new Error(
      'Full spec-sheet URL expected in order to use this component',
    );
  }

  const [imagePlacement, setImagePlacement] = useState<ProfileImagePlacement>({
    horizontal: 0,
    vertical: 0,
    scale: 0,
  });

  const { data } = useProductQuery({
    variables: {
      id: params.productId,
    },
  });

  const productData = data?.product ?? null;

  const updateServerImagePlacements = useUpdateServerImagePlacements();

  const handleChange =
    (sliderType: 'vertical' | 'horizontal' | 'scale') =>
    (_: unknown, newValue: number | number[]) => {
      const placement = { ...imagePlacement };
      placement[sliderType] = newValue as number;
      setImagePlacement(placement);

      updateServerImagePlacements([
        {
          productId: params.productId,
          ...placement,
        },
      ]);
    };
  const handlePrint = () => {
    window.print();
  };

  useEffect(() => {
    if (productData) {
      const p = productData.imagePlacement;
      setImagePlacement({
        horizontal: p.horizontal,
        vertical: p.vertical,
        scale: p.scale,
      });
    }
  }, [productData]);

  if (productData == null) {
    return null;
  }

  return (
    <ManageSpecSheetStyle>
      <div className="header-container">
        <h1 className="header">Adjust Spec Sheet</h1>
        <Button
          startIcon={<PrintIcon />}
          variant={'contained'}
          className="print-button"
          disableElevation
          size="large"
          color={'secondary'}
          onClick={() => handlePrint()}
        >
          Print
        </Button>
      </div>

      <div className="sliders">
        <h3>Adjust Horizontal</h3>
        <Slider
          value={imagePlacement.horizontal}
          onChange={handleChange('horizontal')}
          aria-labelledby="continuous-slider"
          min={0}
          max={150}
        />
        <h3>Adjust Vertical</h3>
        <Slider
          value={imagePlacement.vertical}
          onChange={handleChange('vertical')}
          aria-labelledby="continuous-slider"
          min={10}
          max={90}
        />
        <h3>Adjust Scale</h3>
        <Slider
          value={imagePlacement.scale}
          onChange={handleChange('scale')}
          aria-labelledby="continuous-slider"
          min={0.2}
          max={1.8}
          step={0.1}
        />
      </div>

      {/* Default padding of 0.4" in chrome, so to match the actual print, we need to account for this. */}
      <div
        style={{
          backgroundColor: '#fff',
          padding: '0.4in',
        }}
      >
        <div
          style={{
            width: 'calc(210mm - 0.8in)',
            height: 'calc(297mm - 0.8in)',
            position: 'relative',
            top: 0,
            left: 0,
          }}
        >
          <Sheet
            customer={customerName}
            product={productData}
            unitNumber={params.unitNumber}
            imageXOffset={imagePlacement.horizontal}
            imageYOffset={imagePlacement.vertical}
            imageScale={imagePlacement.scale}
          />
        </div>
      </div>
      <PrintRoot>
        <Sheet
          customer={customerName}
          product={productData}
          unitNumber={params.unitNumber}
          imageXOffset={imagePlacement.horizontal}
          imageYOffset={imagePlacement.vertical}
          imageScale={imagePlacement.scale}
        />
      </PrintRoot>
    </ManageSpecSheetStyle>
  );
};

const ManageSpecSheetStyle = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: auto;
  margin-top: 42px;
  .sliders {
    width: 60%;
    margin-bottom: 30px;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 40px;
    .print-button {
      background: #375954;
    }
  }
`;
