import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

export const PrintHide = styled.div`
  @media print {
    display: none !important;
  }
`;

const DOM_NODE = document.getElementById('print');
if (DOM_NODE == null) {
  throw new Error(
    'No `print` DOM node found. Did you remove it from `index.html`?',
  );
}

export const PrintRoot: React.FC = ({ children }) => {
  return ReactDOM.createPortal(children, DOM_NODE as Element);
};
