import { ListItem, Paper, TextField } from '@mui/material';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  DragDropContext,
  Draggable,
  Droppable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { ExtraRequirement } from '../../types';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';

export interface DraggableListProps {
  items: ExtraRequirement[];
  onDragEnd: OnDragEndResponder;
  remove: (x: ExtraRequirement) => void;
}

export interface DraggableListItemProps {
  item: ExtraRequirement;
  index: number;
  remove: (x: ExtraRequirement) => void;
}

const useStyles = makeStyles({
  draggingListItem: {
    background: '#e0e4e4',
  },
  static: {
    background: '#f6f6f6',
  },
});

const RequirementList: React.VFC<DraggableListProps> = ({
  items,
  onDragEnd,
  remove,
}) => {
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableListItem
                item={item}
                index={index}
                key={item.id}
                remove={remove}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const ExtraRequirementList = React.memo(RequirementList);

export const DraggableListItem: React.FC<DraggableListItemProps> = ({
  item,
  index,
  remove,
}) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided, snapshot) => (
        <Paper>
          <ItemStyle>
            <ListItem
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              className={
                snapshot.isDragging ? classes.draggingListItem : classes.static
              }
            >
              <div className="inner-item">
                <div className="inputs">
                  <TextField
                    className="item-field"
                    id="filled-textarea"
                    placeholder="Feature"
                    helperText="e.g. Moisture Content"
                    value={item.key}
                    size="medium"
                  />
                  <TextField
                    className="item-field"
                    id="filled-textarea"
                    placeholder="Value"
                    helperText="e.g. 12%"
                    value={item.value}
                    size="medium"
                  />
                </div>
                <IconButton
                  onClick={() => remove(item)}
                  className="delete-icon"
                  aria-label="delete"
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </ListItem>
          </ItemStyle>
        </Paper>
      )}
    </Draggable>
  );
};

const ItemStyle = styled.div`
  margin-bottom: 15px;
  .inner-item {
    display: flex;
    width: 100%;
    height: 100%;
    .inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      .item-field {
        margin: 10px 0px;
      }
    }

    .delete-icon {
      margin-left: 15px;
      height: min-content;
      align-self: center;
    }
  }
`;
