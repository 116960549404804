import Fuse from 'fuse.js';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useDebouncedCallback } from 'use-debounce';
import { displayUserErrors } from './errors';
import {
  ProductProfileUpdateImagePlacementsInput,
  useUpdateProfileImagePlacmentMutation,
} from './generated/graphql';
import { ExtraRequirement, Product, ProductState } from './types';

export const mm2ToLmPerM3 = (x: any) =>
    x === 0 || x === '' || x === null ? '' : (1000000 / x).toFixed(2);

export function reorder<T>(
  list: T[],
  startIndex: number,
  endIndex: number,
): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}

export function isNumber(n: string) {
  const f = parseFloat(n);
  return !isNaN(f) && isFinite(f);
}

export function invalidStacking(
  stateWidth: string,
  stateHeight: string,
  stateSubpack: string,
) {
  if (stateSubpack && !isNumber(stateWidth) && !isNumber(stateHeight)) {
    return true;
  }

  if (
    (!isNumber(stateWidth) && isNumber(stateHeight)) ||
    (isNumber(stateWidth) && !isNumber(stateHeight))
  ) {
    return true;
  }

  return false;
}

export function isProductUnchanged(
  state: ProductState,
  saved: Product,
  extraRequirements: ExtraRequirement[],
): boolean {
  return (
    state.name === saved.name &&
    state.notes === saved.notes &&
    state.sizeTolerance === saved.sizeTolerance &&
    state.endUsage === saved.endUsage &&
    state.gradeSpec === saved.gradeSpec &&
    state.lengthSpec === saved.lengthSpec &&
    state.machineSpec === saved.machineSpec &&
    state.ourCode === saved.ourCode &&
    state.assignedCustomerId === saved.assignedCustomer?.id &&
    state.mm2.replace(/0+$/, '') ===
      (saved.mm2?.toFixed(2).toString() ?? '').replace(/0+$/, '') &&
    state.stackingWidth === (saved.stacking?.packWidth.toString() ?? '') &&
    state.stackingHeight === (saved.stacking?.packHeight.toString() ?? '') &&
    state.stackingSubpack === (saved.stacking?.subpack?.toString() ?? '') &&
    requirementsEqual(extraRequirements, saved.extraRequirements)
  );
}

function requirementsEqual(a: ExtraRequirement[], b: ExtraRequirement[]) {
  const first = a.map((x) => [x.key, x.value]);
  const second = b.map((x) => [x.key, x.value]);
  return JSON.stringify(first) === JSON.stringify(second);
}

export function revert(product: Product): ProductState {
  return {
    name: product.name,
    sizeTolerance: product.sizeTolerance,
    stackingWidth: product.stacking?.packWidth?.toString() ?? '',
    stackingHeight: product.stacking?.packHeight?.toString() ?? '',
    stackingSubpack: product.stacking?.subpack ?? '',
    customerReference: product.customerReference,
    extraRequirements: product.extraRequirements,
    gradeSpec: product.gradeSpec,
    lengthSpec: product.lengthSpec,
    machineSpec: product.machineSpec,
    notes: product.notes,
    assignedCustomerId: product.assignedCustomer?.id,
    ourCode: product.ourCode,
    mm2: product.mm2?.toFixed(2).toString() ?? '',
    endUsage: product.endUsage,
  };
}

export function applySearchQuery<T>(data: T[], query: string): T[] {
  if (query === '') return data;

  const fuse = new Fuse(data, {
    keys: ['name', 'ourCode'],
    threshold: 0.3,
    shouldSort: true,
  });
  return fuse.search(query.trim().substring(0, 32)).map((searchResult) => searchResult.item);
}

export function renderError(msg: string) {
  Swal.fire({
    icon: 'error',
    title: 'Oh no!',
    text: msg,
  });
}

export function fileUrl(
  product: Product,
  field: 'imageFile' | 'technicalFile',
) {
  const info = product[field]?.info;
  if (info?.__typename !== 'PresentFile') return undefined;
  return info.accessUrl;
}

export function useUpdateServerImagePlacements() {
  const [mutate] = useUpdateProfileImagePlacmentMutation();

  const update = useDebouncedCallback(
    (placements: ProductProfileUpdateImagePlacementsInput['placements']) => {
      const mutation = mutate({
        variables: {
          input: { placements },
        },
      });

      displayUserErrors('result', mutation, true);
    },
    500,
  );

  // If unmounted, make sure any pending mutations do get applied
  useEffect(
    () => () => {
      update.flush();
    },
    [update],
  );

  return update;
}
