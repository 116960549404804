import styled from 'styled-components';
import { CustomerDrawer } from './CustomerDrawer';
import { ManageCustomer } from './ManageCustomer';
import * as Sentry from '@sentry/react';
import { Routes } from '../../constants';
import { useCustomersQuery } from '../../generated/graphql';

export const CustomersAdmin: React.FC = () => {
  const { customerId } = Routes.Customers.useParams();

  const { loading, error, data } = useCustomersQuery({
    pollInterval: 60000,
  });

  if (error) {
    Sentry.captureMessage('Could not load customers', Sentry.Severity.Error);
  }

  return (
    <CustomersAdminStyle>
      <CustomerDrawer
        selectedId={customerId}
        isLoading={loading}
        error={error}
        customers={data?.customers ?? []}
      />
      {customerId != null ? (
        <ManageCustomer key={customerId} customerId={customerId} />
      ) : (
        <h3 className="none-selected">Please select a customer on the left</h3>
      )}
    </CustomersAdminStyle>
  );
};

const CustomersAdminStyle = styled.div`
  width: 100%;
  margin-left: 300px;

  .none-selected {
    text-align: center;
    margin-top: 100px;
  }
`;
