import { Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styled from 'styled-components';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../constants';
import { useCreateCustomerMutation } from '../../generated/graphql';
import { displayUserErrors } from '../../errors';
import { Queries } from '../../queries/queries';

export interface CreateCustomerModalProps {
  open: boolean;
  handleClose: () => void;
}

export const CreateCustomerModal: React.FC<CreateCustomerModalProps> = ({
  open,
  handleClose,
}) => {
  const [create, { loading }] = useCreateCustomerMutation();

  const [name, setName] = useState('');
  const [notes, setNotes] = useState('');

  const validCustomerData = name !== '';

  const history = useHistory();
  const handleCreate = () => {
    displayUserErrors(
      'result',
      create({
        variables: {
          input: {
            name,
            notes,
          },
        },
        refetchQueries: [Queries.Customers],
        awaitRefetchQueries: true,
      }).then((result) => {
        const customer = result.data?.result.customer?.id;
        if (customer != null) {
          history.push(Routes.Customers.singlePath(customer));
          handleClose();
        }
        return result;
      }),
      true,
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>New Customer</DialogTitle>
      <DialogContent>
        <CreateCustomerStyle>
          <div className="inputs">
            <TextField
              className="item-field"
              placeholder="Name"
              size="medium"
              value={name}
              required
              autoFocus
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              className="item-field"
              placeholder="Notes"
              size="medium"
              value={notes}
              multiline
              minRows={3}
              onChange={(e) => setNotes(e.target.value)}
            />
          </div>

          <Button
            className="create-button"
            onClick={() => handleCreate()}
            startIcon={<AddCircleIcon />}
            variant="contained"
            disableElevation
            disabled={!validCustomerData || loading}
            size="large"
          >
            Create Customer
          </Button>
        </CreateCustomerStyle>
      </DialogContent>
    </Dialog>
  );
};

const CreateCustomerStyle = styled.div`
  margin: 0 50px;
  text-align: center;
  .inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    .item-field {
      margin: 10px 0;
    }
  }

  .create-button {
    margin: 10px 0;
  }
`;
