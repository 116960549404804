import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  selectProducts,
  selectCustomer,
  selectUnitNumber,
} from '../redux/store';
import Divider from '@mui/material/Divider';
import Slider from '@mui/material/Slider';
import { useState } from 'react';
import { Sheet } from './specsheet/Sheet';
import { ProfileImagePlacement } from '../types';
import { PrintHide, PrintRoot } from './PrintVisibility';
import { useUpdateServerImagePlacements } from '../utils';

export interface ProductImagePlacement {
  horizontal: number;
  vertical: number;
  scale: number;
}

export const PrintPage: React.FC = () => {
  const addedProducts = useSelector(selectProducts);
  const unitNumber = useSelector(selectUnitNumber);
  const customer = useSelector(selectCustomer);

  const [imagePlacements, setImagePlacements] = useState<
    Record<ID, ProductImagePlacement | undefined>
  >({});

  const updateServerImagePlacements = useUpdateServerImagePlacements();

  const buildChangeHandler = (
    sliderType: keyof ProfileImagePlacement,
    productId: ID,
  ) => {
    return (_: unknown, newValue: number | number[]) => {
      const placements = { ...imagePlacements };
      const existing = addedProducts.find(
        (x) => x.id === productId,
      )!.imagePlacement;

      placements[productId] = {
        horizontal: existing.horizontal,
        vertical: existing.vertical,
        scale: existing.scale,
        ...placements[productId],
        [sliderType]: newValue as number,
      };
      setImagePlacements(placements);

      updateServerImagePlacements(
        Object.entries(placements).map(([productId, placement]) => ({
          productId: productId as ID,
          ...placement!,
        })),
      );
    };
  };

  if (customer == null) {
    return <div>No customer...</div>;
  }

  return (
    <>
      <PrintHide>
        <PrintPageStyle>
          <div className="header">
            <h2>Printing Products</h2>
            {addedProducts.map((x) => {
              return <div className="name">{x.name}</div>;
            })}
            <h2 className="customer">Customer: {customer.name}</h2>
            <h2>Unit Number: {unitNumber}</h2>
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 40 }} />
          {addedProducts.map((x) => {
            const placement = imagePlacements[x.id] ?? x.imagePlacement;
            return (
              <>
                <div className="adjust-container">
                  <div className="sliders">
                    <h3>Adjust Horizontal</h3>
                    <Slider
                      value={placement.horizontal}
                      onChange={buildChangeHandler('horizontal', x.id)}
                      aria-labelledby="continuous-slider"
                      min={0}
                      max={150}
                    />
                    <h3>Adjust Vertical</h3>
                    <Slider
                      value={placement.vertical}
                      onChange={buildChangeHandler('vertical', x.id)}
                      aria-labelledby="continuous-slider"
                      min={10}
                      max={90}
                    />
                    <h3>Adjust Scale</h3>
                    <Slider
                      value={placement.scale}
                      onChange={buildChangeHandler('scale', x.id)}
                      aria-labelledby="continuous-slider"
                      min={0.2}
                      max={1.8}
                      step={0.1}
                    />
                  </div>
                  <div
                    className="sheet"
                    style={{
                      backgroundColor: '#f3f3f3',
                      padding: '0.4in',
                    }}
                  >
                    <div
                      style={{
                        width: 'calc(210mm - 0.8in)',
                        height: 'calc(297mm - 0.8in)',
                        position: 'relative',
                        top: 0,
                        left: 0,
                      }}
                    >
                      <Sheet
                        customer={customer.name}
                        product={x}
                        unitNumber={unitNumber}
                        imageXOffset={placement.horizontal}
                        imageYOffset={placement.vertical}
                        imageScale={placement.scale}
                      />
                    </div>
                  </div>
                </div>

                <Divider style={{ marginTop: 60, marginBottom: 60 }} />
              </>
            );
          })}
        </PrintPageStyle>
      </PrintHide>
      <PrintRoot>
        {addedProducts.map((x) => {
          const placement = imagePlacements[x.id] ?? x.imagePlacement;
          return (
            <div style={{ backgroundColor: '#fff' }}>
              <div
                style={{
                  width: 'calc(210mm - 0.8in)',
                  height: 'calc(297mm - 0.8in)',
                  position: 'relative',
                  top: 0,
                  overflow: 'hidden',
                }}
              >
                <Sheet
                  customer={customer.name}
                  product={x}
                  unitNumber={unitNumber}
                  imageXOffset={placement.horizontal}
                  imageYOffset={placement.vertical}
                  imageScale={placement.scale}
                />
              </div>
            </div>
          );
        })}
      </PrintRoot>
    </>
  );
};

const PrintPageStyle = styled.div`
  .adjust-container {
    display: flex;

    .sliders {
      width: 300px;
      margin-right: 30px;
      margin-top: 320px;
    }
  }

  .header {
    margin-top: 40px;

    .products {
      display: flex;
      flex-direction: column;
    }

    .customer {
      margin: 20px 0;
    }
  }
`;
