import dateFormat from 'dateformat';
import React from 'react';
import styled from 'styled-components';
import { Product } from '../../types';

export interface GradeDataProps {
  product: Product;
}

export const GradeData: React.FC<GradeDataProps> = ({ product }) => {
  return (
    <GradeDataStyle>
      <div className="ss-profile-name">Profile Name: {product.name}</div>
      <div className="ss-grade-details">
        {product.lastUpdated && (
          <>
            Last updated:{' '}
            <em>
              {dateFormat(
                new Date(product.lastUpdated),
                'yyyy-mm-dd h:MM:ss TT',
              )}
              <br />
            </em>
          </>
        )}
        {product.gradeSpec && (
          <>
            Grade Spec: <em>{product.gradeSpec}</em>
            <br />
          </>
        )}
        {product.machineSpec && (
          <>
            Machine Spec: <em>{product.machineSpec}</em>
            <br />
          </>
        )}
        {product.sizeTolerance && (
          <>
            Size Tolerance: <em>{product.sizeTolerance}</em>
            <br />
          </>
        )}
        {product.endUsage && (
          <>
            End Usage: <em>{product.endUsage}</em>
            <br />
          </>
        )}
        {product.lengthSpec && (
          <>
            Length Spec: <em>{product.lengthSpec}</em>
            <br />
          </>
        )}
        {product.stacking && (
          <>
            Pack WxH:{' '}
            <em>
              {product.stacking.packWidth}x{product.stacking.packHeight}
            </em>
            <br />
            Subpack: <em>{product.stacking.subpack}</em>
            <br />
          </>
        )}
        {product.extraRequirements.map((req) => (
          <span key={req.id}>
            {req.key}: <em>{req.value}</em>
            <br />
          </span>
        ))}
        {product.customerReference && (
          <>
            Customer Reference: <em>{product.customerReference}</em>
          </>
        )}
      </div>
    </GradeDataStyle>
  );
};

const GradeDataStyle = styled.div`
  position: absolute;
  left: 0;
  top: 32mm;
  z-index: 1;

  .ss-grade-details {
    font-size: 0.75em;
  }

  .ss-profile-name {
    font-size: 1.1em;
    margin-bottom: 2mm;
  }
`;
