import React from 'react';
import { Product } from '../../types';
import styled from 'styled-components';

interface SpecSheetDrawerContentsProps {
  customerName: string;
  productData: Product;
}

export const SpecSheetDrawerContents: React.FC<SpecSheetDrawerContentsProps> =
  ({ customerName, productData }) => {
    return (
      <SpecSheetDrawerContentsStyle>
        <div className="item">
          <div className="label">Customer</div>
          <div className="values"> {customerName}</div>
        </div>
        <div className="item">
          <div className="label">Product</div>
          <div className="values">
            <div className="sub-label">Name:</div>
            <div className="sub-value">{productData.name}</div>
            <div className="sub-label">Grace Spec:</div>
            <div className="sub-value">{productData.gradeSpec}</div>
            <div className="sub-label">Machine Spec:</div>
            <div className="sub-value">{productData.machineSpec}</div>
            <div className="sub-label">Size Tolerance:</div>
            <div className="sub-value">{productData.sizeTolerance}</div>
            <div className="sub-label">End Usage:</div>
            <div className="sub-value">{productData.endUsage}</div>
            <div className="sub-label">Customer Reference:</div>
            <div className="sub-value">
              {productData.customerReference
                ? productData.customerReference
                : 'N/A'}
            </div>
          </div>
        </div>
      </SpecSheetDrawerContentsStyle>
    );
  };

const SpecSheetDrawerContentsStyle = styled.div`
  padding: 0px 20px;
  .item {
    color: #375954;

    .label {
      font-weight: 600;
      font-size: x-large;
    }
    .values {
      font-size: larger;
      margin-bottom: 25px;

      .sub-label {
        margin-top: 10px;
        font-size: large;
        font-weight: 600;
      }
    }
  }
`;
