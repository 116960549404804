import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { IconButton, Modal, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { env, releaseProfile } from '../runtime-environment';
import source from '../../CHANGELOG.md';

type VersionNumberKind = 'home' | 'bar';

export const VersionNumber: React.FC<{ kind: VersionNumberKind }> = ({
  kind,
}) => {
  const [open, setOpen] = React.useState(false);
  const [state, setState] = useState('');

  useEffect(() => {
    fetch(source)
      .then((res) => res.text())
      .then((post) => setState(post))
      .catch((err) => console.error(err));
    return () => {};
  }, []);

  return (
    <>
      <VersionStyle>
        <Typography
          onClick={() => setOpen(true)}
          align="left"
          style={
            kind === 'home'
              ? {
                  color: '#375954',
                  position: 'fixed',
                  top: 20,
                  right: 20,
                  fontWeight: 500,
                  textTransform: 'uppercase',
                }
              : {
                  color: '#375954',
                  textTransform: 'uppercase',
                }
          }
        >
          {env.version} {releaseAnnotation()}
        </Typography>
      </VersionStyle>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalStyle>
          <IconButton
            onClick={() => setOpen(false)}
            style={{
              position: 'absolute',
              top: '1em',
              right: '1em',
            }}
          >
            <CloseIcon style={{ padding: 0 }} />
          </IconButton>
          <ReactMarkdown>{state}</ReactMarkdown>
        </ModalStyle>
      </Modal>
    </>
  );
};

function releaseAnnotation() {
  const profile = releaseProfile();
  if (profile === 'local') return 'local';
  if (profile === 'staging') return 'staging';
  if (profile === 'feat-eval') return 'feature evaluation';
  return '';
}

const VersionStyle = styled.div`
  :hover {
    cursor: pointer;
  }
`;

const ModalStyle = styled(Paper)`
  font-family: Roboto;
  overflow-y: auto;
  left: 15vw;
  top: 5vh;
  width: 70vw;
  height: 90vh;
  position: fixed;
  outline: none;
  padding: 2em;

  & * {
    padding: 0.3em;
    list-style-position: inside;
  }

  h2 {
    padding-top: 1.5em;
  }

  li {
    padding-left: 10px;
  }
`;
