import { Dialog, DialogTitle, DialogContent, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styled from 'styled-components';
import React, { useState } from 'react';
import { ExtraRequirement } from '../../types';
import Button from '@mui/material/Button';
import { nanoid } from 'nanoid';

export interface AddExtraRequirementsModalProps {
  open: boolean;
  create: (x: ExtraRequirement) => void;
  handleClose: () => void;
}

export const AddExtraRequirementsModal: React.FC<
  AddExtraRequirementsModalProps
> = ({ open, create, handleClose }) => {
  const [key, setKey] = useState('');
  const [value, setValue] = useState('');
  const reset = () => {
    setKey('');
    setValue('');
  };

  const handleCreate = () => {
    create({ id: `local:${nanoid()}` as ID, key, value });
    reset();
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add New Requirement</DialogTitle>
      <DialogContent>
        <AddERStyle>
          <div className="inputs">
            <TextField
              className="item-field"
              placeholder="Feature"
              size="medium"
              value={key}
              helperText="e.g. Moisture Content"
              autoFocus
              onChange={(e) => setKey(e.target.value)}
            />
            <TextField
              className="item-field"
              placeholder="Value"
              size="medium"
              value={value}
              helperText="e.g. 12%"
              onChange={(e) => setValue(e.target.value)}
            />
          </div>

          <Button
            className="create-ER"
            onClick={() => handleCreate()}
            startIcon={<AddCircleIcon />}
            variant="contained"
            disableElevation
            size="large"
          >
            Create
          </Button>
        </AddERStyle>
      </DialogContent>
    </Dialog>
  );
};

const AddERStyle = styled.div`
  margin: 0px 50px;
  text-align: center;

  .inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    .item-field {
      margin: 10px 0px;
    }
  }

  .create-ER {
    margin: 10px 0px;
  }
`;
