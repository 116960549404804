import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Routes } from '../../constants';
import { useCustomersQuery, useProductsQuery } from '../../generated/graphql';
import { CustomerDrawer } from '../customers/CustomerDrawer';
import { PrintHide } from '../PrintVisibility';
import { ProductDrawer } from '../products/ProductDrawer';
import { ManageSpecSheet } from './ManageSpecSheet';
import { SpecSheetDrawer } from './SpecSheetDrawer';

export const SpecSheetAdmin: React.FC = () => {
  const params = Routes.SpecSheet.useParams();
  const history = useHistory();
  const [unitNumberState, setUnitNumberState] = useState('');

  const customers = useCustomersQuery();
  const products = useProductsQuery();

  const customerName =
    customers.data?.customers.find((x) => x.id === params?.customerId)?.name ??
    'Unknown';

  const displayStep = () => {
    if (params == null) {
      return (
        <h2 className="content-header">
          To create a Spec Sheet, select a customer on the left
        </h2>
      );
    }

    if ('unitNumber' in params) {
      return <ManageSpecSheet customerName={customerName} />;
    }

    if ('productId' in params) {
      const { customerId, productId } = params;
      return (
        <div className="final-summary">
          <h2 className="content-header">
            Enter a Unit Number, then
            <Button
              className="continue"
              onClick={() => {
                history.push(
                  Routes.SpecSheet.path({
                    customerId,
                    productId,
                    unitNumber: unitNumberState,
                  }),
                );
              }}
              variant="contained"
              disableElevation
              disabled={unitNumberState === ''}
            >
              Continue
            </Button>
          </h2>
          <TextField
            className="unit-number"
            id="filled-textarea"
            label="Unit Number"
            type="number"
            variant="filled"
            value={unitNumberState}
            onChange={(e) => setUnitNumberState(e.target.value)}
          />
        </div>
      );
    }

    return (
      <h2 className="content-header">
        Now select a product for {customerName} on the left
      </h2>
    );
  };

  return (
    <PrintHide>
      <SpecSheetAdminStyle>
        {params == null ? (
          <CustomerDrawer
            selectedId={null}
            isLoading={customers.loading}
            error={customers.error}
            customers={customers.data?.customers ?? []}
            specSheet
          />
        ) : !('productId' in params) ? (
          <ProductDrawer
            selectedId={null}
            products={products.data?.parentProducts ?? []}
            byCustomer={params.customerId}
          />
        ) : (
          <SpecSheetDrawer
            productId={params.productId}
            customerName={customerName}
          />
        )}

        {displayStep()}
      </SpecSheetAdminStyle>
    </PrintHide>
  );
};

const SpecSheetAdminStyle = styled.div`
  width: 100%;
  margin-left: 187.5px;
  .final-summary {
    text-align: center;
  }
  .content-header {
    text-align: center;
    margin: 50px 0px;
    .continue {
      margin-left: 15px;
      background: #375954;
    }
  }
  .summary {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    margin-left: 100px;
    font-size: larger;
    font-weight: 600;
    color: #375954;
    .unit-number {
      margin-bottom: 20px;
      max-width: 400px;
    }
    .item {
      display: inline-flex;
      width: 400px;
    }
    .label {
      margin-right: 20px;
    }
  }
`;
