import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Typography,
} from '@mui/material';

import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../constants';

export interface CustomerSpecificModalProps {
  open: boolean;
  handleClose: () => void;
  childProducts: Array<{
    id: ID;
    assignedCustomer?: {
      name: string;
    } | null;
  }>;
}

export const CustomerSpecificModal: React.FC<CustomerSpecificModalProps> = ({
  open,
  handleClose,
  childProducts,
}) => {
  const history = useHistory();
  const handleSelect = (id: ID) => {
    history.push(Routes.Products.singlePath(id));
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Customer Specific Products</DialogTitle>
      <DialogContent>
        <CustomerListStyle>
          {childProducts.length > 0 ? (
            <List className="list">
              {childProducts.map((child) => (
                <ListItem
                  button
                  key={child.id}
                  onClick={() => handleSelect(child.id)}
                >
                  <Typography>
                    {child.assignedCustomer?.name || 'Unknown Customer'}
                  </Typography>
                </ListItem>
              ))}
            </List>
          ) : (
            'None currently added.'
          )}
        </CustomerListStyle>
      </DialogContent>
    </Dialog>
  );
};

const CustomerListStyle = styled.div`
  margin: 0px 50px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;
