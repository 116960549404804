import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import { displayUserErrors } from '../../errors';
import { useUploadProductTechnicalFileMutation } from '../../generated/graphql';
import axios from 'axios';

export interface TechnicalFileProps {
  productId: ID;
  technicalFileName?: string;
  technicalFileUrl?: string;
  onUploaded: () => void;
}

export const TechnicalFile: React.FC<TechnicalFileProps> = ({
  productId,
  technicalFileName,
  technicalFileUrl,
  onUploaded,
}) => {
  const [getHandle] = useUploadProductTechnicalFileMutation();

  type Status = 'uploading' | 'downloading' | 'idle';
  const [status, setStatus] = useState<Status>('idle');

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <TechnicalFileStyle>
      <div className="technical-file">
        <div className="header-container">
          <h3 className="header">Technical File</h3>
        </div>
        <div className="file-actions">
          <div className="file-name">{technicalFileName ?? ''}</div>

          <div className="buttons">
            <form ref={formRef}>
              <Button
                disabled={status !== 'idle'}
                className="button upload"
                component="label"
                onChange={async (event: any) => {
                  if (
                    formRef.current == null ||
                    !event.target?.validity.valid
                  ) {
                    return;
                  }

                  const file = event.target.files[0] as File;
                  formRef.current.reset();

                  let url: string | null = null;
                  const mutation = getHandle({
                    variables: {
                      input: {
                        productId,
                        filename: file.name,
                      },
                    },
                  }).then((x) => {
                    url = x.data?.result.uploadUrl ?? null;
                    return x;
                  });

                  setStatus('uploading');
                  await displayUserErrors('result', mutation, true);

                  if (url == null) {
                    return;
                  }

                  await axios.put(url, file, {
                    headers: {
                      'x-ms-date': new Date().toISOString(),
                      'x-ms-version': '2020-12-06',
                      'x-ms-blob-type': 'BlockBlob',
                    },
                  });

                  setStatus('idle');
                  onUploaded();
                }}
                startIcon={<AddCircleIcon />}
                variant={'contained'}
                disableElevation
              >
                Upload File
                <input type="file" accept=".dwg, .dxf, .tcw" hidden />
              </Button>
            </form>
            {technicalFileUrl && technicalFileName && (
              <Button
                disabled={status !== 'idle'}
                className="button download"
                variant={'contained'}
                startIcon={<DownloadIcon />}
                onClick={async () => {
                  setStatus('downloading');
                  const { data: blob } = await axios.get(technicalFileUrl, {
                    responseType: 'blob',
                  });

                  const a = document.createElement('a');
                  const url = URL.createObjectURL(blob as Blob);
                  a.href = url;
                  a.download = technicalFileName;
                  a.click();
                  URL.revokeObjectURL(url);
                  setStatus('idle');
                }}
              >
                Download File
              </Button>
            )}
          </div>
        </div>
      </div>
    </TechnicalFileStyle>
  );
};

const TechnicalFileStyle = styled.div`
  .file-actions {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .buttons {
      display: inline-flex;

      .download {
        margin-left: 20px;
        color: white;
        a {
          all: unset;
        }
      }
    }
  }
`;
