import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import styled from 'styled-components';
import { Routes } from '../constants';

export const HomePage: React.FC = () => {
  return (
    <HomeStyle>
      <h1 className="header">Hello!</h1>
      <ButtonGroup
        orientation="vertical"
        color="inherit"
        aria-label="vertical contained primary button group"
        variant="text"
        size="large"
      >
        <Button disableElevation href={Routes.SpecSheet.path()}>
          New Spec Sheet
        </Button>
        <Button disableElevation href={Routes.Products.allPath()}>
          Manage Products
        </Button>
        <Button disableElevation href={Routes.Customers.allPath()}>
          Manage Customers
        </Button>
      </ButtonGroup>
    </HomeStyle>
  );
};

export const HomeStyle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  .header {
    margin-top: 10px;
    padding-bottom: 30px;
    text-align: center;
    color: #375954;
    font-weight: bold;
  }
`;
