import React from 'react';

export const ICPRTable = () => (
  <table className="ss-table">
    <colgroup>
      <col span={1} width="35" />
      <col span={1} width="35" />
      <col span={1} width="35" />
      <col span={1} width="35" />
      <col span={1} width="190" />
    </colgroup>
    <tbody>
      <tr>
        <th colSpan={4}>ICPR COMPLIANT?</th>
        <td
          rowSpan={2}
          style={{ fontSize: '0.6em', verticalAlign: 'top', height: 63 }}
        >
          Actions taken if not compliant:
        </td>
      </tr>
      <tr>
        <td>YES</td>
        <td />
        <td>NO</td>
        <td />
      </tr>
    </tbody>
  </table>
);

export const SizeCheckTable = () => (
  <table className="ss-table">
    <colgroup>
      <col span={1} width="20" />
      <col span={1} width="145" />
      <col span={1} width="20" />
      <col span={1} width="145" />
    </colgroup>
    <tbody>
      <tr>
        <th style={{ borderTop: 'none' }} colSpan={2}>
          Start Time:
        </th>
        <th style={{ borderTop: 'none' }} colSpan={2}>
          Stop Time:
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Hourly Size Check</th>
        <th colSpan={2}>Name:</th>
      </tr>
      {[1, 2, 3, 4, 5, 6].map((x) => (
        <tr key={x}>
          <td style={{ textAlign: 'center' }}>{x}</td>
          <td style={{ textAlign: 'center' }}>x</td>
          <td style={{ textAlign: 'center' }}>{x + 6}</td>
          <td style={{ textAlign: 'center' }}>x</td>
        </tr>
      ))}
    </tbody>
  </table>
);

export const RejectsTable = () => (
  <table className="ss-table">
    <colgroup>
      <col span={1} width="70" />
      <col span={1} width="260" />
    </colgroup>
    <tbody>
      <tr>
        <th style={{ borderTop: 'none', verticalAlign: 'top' }}>Rejects</th>
        <td
          style={{
            borderTop: 'none',
            fontSize: '0.6em',
            verticalAlign: 'top',
            height: 57,
          }}
        >
          Reason for rejects (Phytosanitary):
        </td>
      </tr>
      <tr>
        <th colSpan={2} style={{ textAlign: 'center' }}>
          Reject Cause
        </th>
      </tr>
    </tbody>
  </table>
);

export const RHSTable = () => (
  <table className="ss-table">
    <colgroup>
      <col span={1} width="20" />
      <col span={1} width="180" />
      <col span={1} width="50" />
      <col span={1} width="90" />
      <col span={1} width="120" />
    </colgroup>
    <tbody>
      <tr>
        <th colSpan={2}>Date:</th>
        <th colSpan={3}>Product Description:</th>
      </tr>
      <tr>
        <th colSpan={2}>Signature:</th>
        <th colSpan={3}>Product Size:</th>
      </tr>
      <tr>
        <th colSpan={2}>GT Pack Number:</th>
        <th style={{ textAlign: 'center' }}>Length</th>
        <th style={{ textAlign: 'center' }}>Total Pieces</th>
        <th style={{ textAlign: 'center' }}>Inward Pack #</th>
      </tr>
      {Array(11)
        .fill(undefined)
        .map((_v, i) => (
          <tr key={i}>
            <td>GT</td>
            <td />
            <td />
            <td />
            <td />
          </tr>
        ))}
    </tbody>
  </table>
);

export const RejectCauseTable: React.FC = () => (
  <table className="ss-table">
    <colgroup>
      <col span={1} width="92" />
      <col span={1} width="92" />
      <col span={1} width="92" />
      <col span={1} width="92" />
      <col span={1} width="92" />
      <col span={1} width="92" />
      <col span={1} width="92" />
      <col span={1} width="92" />
    </colgroup>
    <tbody>
      <tr style={{ fontSize: 'small' }}>
        <th>Steps | Miss | Undersize</th>
        <th>Splits</th>
        <th>Resin Pockets</th>
        <th>Knots</th>
        <th>Broken</th>
        <th>Wet | Rot | Sap Stain</th>
        <th>Chain Damage</th>
        <th>Other - Give Reason</th>
      </tr>
      <tr style={{ height: 85 }}>
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
        <td />
      </tr>
      <tr style={{ fontWeight: 'bold' }}>
        <td colSpan={3} >
          Total Reject LM
        </td>
        <td colSpan={2}>
          &nbsp;$ Value per Meter
        </td>
        <td colSpan={3}>
          Total Reject Value
        </td>
      </tr>
      <tr style={{ height: '25px' }}>
        <td colSpan={3} />
        <td colSpan={2} />
        <td colSpan={3} />
      </tr>
    </tbody>
  </table>
);

export const StickerCheckTable: React.FC = () => (
  <table className="ss-table">
    <colgroup>
      <col span={1} width="252" />
      <col span={1} width="230" />
      <col span={1} width="260" />
    </colgroup>
    <tbody>
    <tr>
      <th style={{ textAlign: 'left' }}>
        Sticker Check
      </th>
      <th style={{ textAlign: 'left' }}>
        Signature 1
      </th>
      <th style={{ textAlign: 'left' }}>
        Signature 2
      </th>
    </tr>
    <tr style={{ height: '25px' }}>
      <td></td>
      <td></td>
      <td></td>
    </tr>
    </tbody>
  </table>
);

export default interface CustomerProps {
  customer: any;
}

export const Tables: React.FC<CustomerProps> = ({ customer }) => (
  <div className="ss-tables">
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <div>
        <ICPRTable />
        <SizeCheckTable />
        <RejectsTable />
      </div>
      <RHSTable />
    </div>
    <RejectCauseTable />
    {customer === 'Citi' && <StickerCheckTable/>}
    {customer === 'Porta' && <StickerCheckTable/>}
  </div>
);
