import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import { getBackendToken } from './auth.service';

import introspectionResult from '../generated/introspection-result';
import { env } from '../runtime-environment';

const httpLink = createUploadLink({
  uri: env.serverUri,
});

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getBackendToken();
  return {
    headers: {
      ...headers,
      // Requests for this specific app can be found in Apollo Studio
      'apollographql-client-name': 'profile-sheets',
      'apollographql-client-version': env.version || 'local',
      // Authenticate with the Genia API
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  };
});

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {},
});

const sentryLink = new SentryLink({
  uri: env.serverUri,
  setTransaction: true,
  setFingerprint: true,
  attachBreadcrumbs: {},
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, sentryLink, httpLink as any]),
  cache,
});
