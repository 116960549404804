import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: ID;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
};

export type Debtor = {
  __typename: 'Debtor';
  id: Scalars['ID'];
};

export type DebtorInvoice = {
  __typename: 'DebtorInvoice';
  id: Scalars['ID'];
};

export type DebtorInvoiceLine = {
  __typename: 'DebtorInvoiceLine';
  id: Scalars['ID'];
};

/** Information about a file that has been deleted. */
export type DeletedFile = {
  __typename: 'DeletedFile';
  deletionReason: FileDeletionReason;
};

/** A handle to a file in Azure Blob Storage. */
export type File = {
  __typename: 'File';
  /** Mime-type of the file, e.g. "application/pdf". */
  contentType: Scalars['String'];
  /** When, if at all, the file will expire or did expire. */
  expireAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Data relating to the file, different depending on the file's status */
  info: FileInfo;
  /** Name of the file, if specified when creating an upload handle. */
  name?: Maybe<Scalars['String']>;
};

export enum FileDeletionReason {
  /** File was deleted automatically because it expired. */
  Expired = 'EXPIRED'
}

export type FileInfo = DeletedFile | PresentFile;

export enum FilePermissions {
  /** The default. Files can only be read. */
  Read = 'READ',
  /**
   * Files can be updated after creation - an upload url can always be
   * requested. However unlike the `accessUrl` field, doing so requires
   * the admin scope.
   */
  ReadUpdate = 'READ_UPDATE'
}

export type Fulfilment = {
  __typename: 'Fulfilment';
  id: Scalars['ID'];
};

export type FulfilmentLine = {
  __typename: 'FulfilmentLine';
  id: Scalars['ID'];
};

export type FulfilmentLinePack = {
  __typename: 'FulfilmentLinePack';
  id: Scalars['ID'];
};

export type Kiln = {
  __typename: 'Kiln';
  id: Scalars['ID'];
};

export type KilnCharge = {
  __typename: 'KilnCharge';
  chargeNumber: Scalars['String'];
  id: Scalars['ID'];
};

export type KilnChargeItem = {
  __typename: 'KilnChargeItem';
  id: Scalars['ID'];
};

export type Location = {
  __typename: 'Location';
  id: Scalars['ID'];
};

export type Mutation = {
  __typename: 'Mutation';
  /** Create a `ProductProfile`. */
  productProfileCreate: ProductProfileCreatePayload;
  /** Delete a `ProductProfile` and it's children, if any. */
  productProfileDelete: ProductProfileDeletePayload;
  /**
   * Duplicates a `ProductProfile`. Does not duplicate children. The created
   * product will not have an image nor a technical file, even if what was
   * duplicated did. If duplicating a child, the new product will be under the same
   * parent. The customer assignment, if any, remains.
   */
  productProfileDuplicate: ProductProfileDuplicatePayload;
  /** Update fields on an existing `ProductProfile`. */
  productProfileUpdate: ProductProfileUpdatePayload;
  /** Update the default positioning of the product's image on a spec sheet. */
  productProfileUpdateImagePlacements: ProductProfileUpdateImagePlacementsPayload;
  /** Upload a profile image on a `ProductProfile`. */
  productProfileUploadImage: ProductProfileUploadImagePayload;
  /** Upload a technical file on a `ProductProfile`. */
  productProfileUploadTechnicalFile: ProductProfileUploadTechnicalFilePayload;
  /** Create a customer. */
  profileCustomerCreate: ProfileCustomerCreatePayload;
  /** Soft delete a customer. */
  profileCustomerDelete: ProfileCustomerDeletePayload;
  /** Update data on a customer. */
  profileCustomerUpdate: ProfileCustomerUpdatePayload;
  /**
   * Get one or more handles, which can be used to update existing files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned. Requires the `Documents.Admin` scope, and the files must be
   * configured with update permissions.
   */
  updateHandlesGet: UpdateHandlesGetPayload;
  /**
   * Get one or more handles, which can be used to upload files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned.
   */
  uploadHandlesGet: UploadHandlesGetPayload;
};


export type MutationProductProfileCreateArgs = {
  input: ProductProfileCreateInput;
};


export type MutationProductProfileDeleteArgs = {
  input: ProductProfileDeleteInput;
};


export type MutationProductProfileDuplicateArgs = {
  input: ProductProfileDuplicateInput;
};


export type MutationProductProfileUpdateArgs = {
  input: ProductProfileUpdateInput;
};


export type MutationProductProfileUpdateImagePlacementsArgs = {
  input: ProductProfileUpdateImagePlacementsInput;
};


export type MutationProductProfileUploadImageArgs = {
  input: ProductProfileUploadImageInput;
};


export type MutationProductProfileUploadTechnicalFileArgs = {
  input: ProductProfileUploadTechnicalFileInput;
};


export type MutationProfileCustomerCreateArgs = {
  input: ProfileCustomerCreateInput;
};


export type MutationProfileCustomerDeleteArgs = {
  input: ProfileCustomerDeleteInput;
};


export type MutationProfileCustomerUpdateArgs = {
  input: ProfileCustomerUpdateInput;
};


export type MutationUpdateHandlesGetArgs = {
  input: UpdateHandlesGetInput;
};


export type MutationUploadHandlesGetArgs = {
  input: UploadHandlesGetInput;
};

export type PackLog = {
  __typename: 'PackLog';
  id: Scalars['ID'];
  packNumber: Scalars['String'];
};

export type PackWorkstation = {
  __typename: 'PackWorkstation';
  id: Scalars['ID'];
};

export type PickGroup = {
  __typename: 'PickGroup';
  id: Scalars['ID'];
};

/** Information about a file that exists (though might not yet be uploaded). */
export type PresentFile = {
  __typename: 'PresentFile';
  /**
   * The base URL, but with SAS query parameters providing read access to the
   * file for approximately 60 minutes.
   */
  accessUrl: Scalars['String'];
  /** URI for the location of the blob. */
  baseUrl: Scalars['String'];
  /** Permissions configured for the file. */
  permissions: FilePermissions;
};

/** A product profile */
export type ProductProfile = {
  __typename: 'ProductProfile';
  /** The `ProfileCustomer` associated with this product, if any. */
  assignedCustomer?: Maybe<ProfileCustomer>;
  /** Child products. */
  children: Array<ProductProfile>;
  customerReference: Scalars['String'];
  endUsage: Scalars['String'];
  /** Ordered list of extra requirements for the product. */
  extraRequirements: Array<ProductProfileExtraRequirement>;
  gradeSpec: Scalars['String'];
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Uploaded image for this product, if any. */
  imageFile?: Maybe<File>;
  /** How the image should be placed on a profile sheet by default. */
  imagePlacement: ProductProfileImagePlacement;
  lastUpdated: Scalars['DateTime'];
  lengthSpec: Scalars['String'];
  machineSpec: Scalars['String'];
  mm2?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  notes: Scalars['String'];
  ourCode: Scalars['String'];
  /** The product this product is based upon, if any */
  parent?: Maybe<ProductProfile>;
  sizeTolerance: Scalars['String'];
  /** How the product is built into packs. */
  stacking?: Maybe<ProductProfileStacking>;
  /** Uploaded technical file for this product, if any. */
  technicalFile?: Maybe<File>;
};

export type ProductProfileCreateError = ProductProfileCreateInvalidNestingError | ProductProfileCreateNotFoundError;

export type ProductProfileCreateInput = {
  parentId?: InputMaybe<Scalars['ID']>;
};

export type ProductProfileCreateInvalidNestingError = UserError & {
  __typename: 'ProductProfileCreateInvalidNestingError';
  message: Scalars['String'];
};

export type ProductProfileCreateNotFoundError = UserError & {
  __typename: 'ProductProfileCreateNotFoundError';
  message: Scalars['String'];
};

export type ProductProfileCreatePayload = {
  __typename: 'ProductProfileCreatePayload';
  /** The created `ProductProfile`, null on failure. */
  product?: Maybe<ProductProfile>;
  userErrors: Array<ProductProfileCreateError>;
};

export type ProductProfileDeleteInput = {
  productId: Scalars['ID'];
};

export type ProductProfileDeletePayload = {
  __typename: 'ProductProfileDeletePayload';
  userErrors: Array<UserError>;
};

export type ProductProfileDuplicateError = ProductProfileDuplicateInvalidNestingError | ProductProfileDuplicateNotFoundError;

export type ProductProfileDuplicateInput = {
  /** Whether to make the duplicated product a child of the original. Fails if the original is a child product. */
  child: Scalars['Boolean'];
  /** The product to duplicate */
  productId: Scalars['ID'];
};

export type ProductProfileDuplicateInvalidNestingError = UserError & {
  __typename: 'ProductProfileDuplicateInvalidNestingError';
  message: Scalars['String'];
};

export type ProductProfileDuplicateNotFoundError = UserError & {
  __typename: 'ProductProfileDuplicateNotFoundError';
  message: Scalars['String'];
};

export type ProductProfileDuplicatePayload = {
  __typename: 'ProductProfileDuplicatePayload';
  newProduct?: Maybe<ProductProfile>;
  userErrors: Array<ProductProfileDuplicateError>;
};

/** An extra requirement for the production of the associated product. */
export type ProductProfileExtraRequirement = {
  __typename: 'ProductProfileExtraRequirement';
  /** Unique identifier. */
  id: Scalars['ID'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ProductProfileExtraRequirementInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

/** Describes how the image for the specified product should be placed by default. */
export type ProductProfileImagePlacement = {
  __typename: 'ProductProfileImagePlacement';
  horizontal: Scalars['Float'];
  /** Unique identifier. */
  id: Scalars['ID'];
  scale: Scalars['Float'];
  vertical: Scalars['Float'];
};

export type ProductProfileImagePlacementUpdateInput = {
  horizontal: Scalars['Float'];
  /** The product to update */
  productId: Scalars['ID'];
  scale: Scalars['Float'];
  vertical: Scalars['Float'];
};

/** Describes how a pack of the product is typically built. */
export type ProductProfileStacking = {
  __typename: 'ProductProfileStacking';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Height of a pack in pieces. */
  packHeight: Scalars['Int'];
  /** Width of a pack in pieces. */
  packWidth: Scalars['Int'];
  /** Composition of segments within a pack. */
  subpack: Scalars['String'];
};

export type ProductProfileStackingInput = {
  packHeight: Scalars['Int'];
  packWidth: Scalars['Int'];
  subpack: Scalars['String'];
};

export type ProductProfileUpdateError = ProductProfileUpdateExcessivePayloadError | ProductProfileUpdateNotFoundError;

export type ProductProfileUpdateExcessivePayloadError = UserError & {
  __typename: 'ProductProfileUpdateExcessivePayloadError';
  message: Scalars['String'];
};

export type ProductProfileUpdateImagePlacementsError = ProductProfileUpdateImagePlacementsNotFoundError;

export type ProductProfileUpdateImagePlacementsInput = {
  /** Updates to perform */
  placements: Array<ProductProfileImagePlacementUpdateInput>;
};

export type ProductProfileUpdateImagePlacementsNotFoundError = UserError & {
  __typename: 'ProductProfileUpdateImagePlacementsNotFoundError';
  message: Scalars['String'];
};

export type ProductProfileUpdateImagePlacementsPayload = {
  __typename: 'ProductProfileUpdateImagePlacementsPayload';
  /** The updated `ProductProfile`s, null on failure. */
  products?: Maybe<Array<ProductProfile>>;
  userErrors: Array<ProductProfileUpdateImagePlacementsError>;
};

export type ProductProfileUpdateInput = {
  /** The customer to assign to this product. */
  assignedCustomerId?: InputMaybe<Scalars['ID']>;
  customerReference: Scalars['String'];
  endUsage: Scalars['String'];
  /** The extra requirements for this product, ordered as provided. */
  extraRequirements: Array<ProductProfileExtraRequirementInput>;
  gradeSpec: Scalars['String'];
  lengthSpec: Scalars['String'];
  machineSpec: Scalars['String'];
  mm2?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  notes: Scalars['String'];
  ourCode: Scalars['String'];
  /** The product to update */
  productId: Scalars['ID'];
  sizeTolerance: Scalars['String'];
  stacking?: InputMaybe<ProductProfileStackingInput>;
};

export type ProductProfileUpdateNotFoundError = UserError & {
  __typename: 'ProductProfileUpdateNotFoundError';
  message: Scalars['String'];
};

export type ProductProfileUpdatePayload = {
  __typename: 'ProductProfileUpdatePayload';
  /** The updated `ProductProfile`, null on failure. */
  product?: Maybe<ProductProfile>;
  userErrors: Array<ProductProfileUpdateError>;
};

export type ProductProfileUploadImageError = ProductProfileUploadImageNotFoundError;

export type ProductProfileUploadImageInput = {
  productId: Scalars['ID'];
};

export type ProductProfileUploadImageNotFoundError = UserError & {
  __typename: 'ProductProfileUploadImageNotFoundError';
  message: Scalars['String'];
};

export type ProductProfileUploadImagePayload = {
  __typename: 'ProductProfileUploadImagePayload';
  uploadUrl?: Maybe<Scalars['String']>;
  userErrors: Array<ProductProfileUploadImageError>;
};

export type ProductProfileUploadTechnicalFileError = ProductProfileUploadTechnicalFileNotFoundError;

export type ProductProfileUploadTechnicalFileInput = {
  filename: Scalars['String'];
  productId: Scalars['ID'];
};

export type ProductProfileUploadTechnicalFileNotFoundError = UserError & {
  __typename: 'ProductProfileUploadTechnicalFileNotFoundError';
  message: Scalars['String'];
};

export type ProductProfileUploadTechnicalFilePayload = {
  __typename: 'ProductProfileUploadTechnicalFilePayload';
  uploadUrl?: Maybe<Scalars['String']>;
  userErrors: Array<ProductProfileUploadTechnicalFileError>;
};

/** A customer for whom we make products using profiles */
export type ProfileCustomer = {
  __typename: 'ProfileCustomer';
  assignedProducts: Array<ProductProfile>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Short name for the customer. */
  name: Scalars['String'];
  /** Free-text for providing information about the customer. */
  notes: Scalars['String'];
};

export type ProfileCustomerCreateInput = {
  name: Scalars['String'];
  notes: Scalars['String'];
};

export type ProfileCustomerCreatePayload = {
  __typename: 'ProfileCustomerCreatePayload';
  /** The created `ProfileCustomer`, null on failure. */
  customer?: Maybe<ProfileCustomer>;
  userErrors: Array<UserError>;
};

export type ProfileCustomerDeleteInput = {
  id: Scalars['ID'];
};

export type ProfileCustomerDeletePayload = {
  __typename: 'ProfileCustomerDeletePayload';
  userErrors: Array<UserError>;
};

export type ProfileCustomerUpdateError = ProfileCustomerUpdateNotFoundError;

export type ProfileCustomerUpdateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  notes: Scalars['String'];
};

export type ProfileCustomerUpdateNotFoundError = UserError & {
  __typename: 'ProfileCustomerUpdateNotFoundError';
  message: Scalars['String'];
};

export type ProfileCustomerUpdatePayload = {
  __typename: 'ProfileCustomerUpdatePayload';
  /** The updated `ProfileCustomer`, null on failure. */
  customer?: Maybe<ProfileCustomer>;
  userErrors: Array<ProfileCustomerUpdateError>;
};

export type Query = {
  __typename: 'Query';
  _null?: Maybe<Scalars['String']>;
  /** Get a `File` by id */
  file?: Maybe<File>;
  /** Get `File`s by id */
  files: Array<File>;
  /** Fetch a product by ID, returning `null` if not found. */
  productProfile?: Maybe<ProductProfile>;
  /** Fetch products, optionally filtered by ID. */
  productProfiles: Array<ProductProfile>;
  /** Fetch a customer by ID, returning `null` if not found. */
  profileCustomer?: Maybe<ProfileCustomer>;
  /** Fetch customers, optionally filtered by ID. */
  profileCustomers: Array<ProfileCustomer>;
};


export type QueryFileArgs = {
  id: Scalars['ID'];
};


export type QueryFilesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryProductProfileArgs = {
  id: Scalars['ID'];
};


export type QueryProductProfilesArgs = {
  excludeChildren?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryProfileCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryProfileCustomersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Run = {
  __typename: 'Run';
  id: Scalars['ID'];
};

export type RunInfeedPack = {
  __typename: 'RunInfeedPack';
  packId: Scalars['ID'];
};

export type RunOutfeedPack = {
  __typename: 'RunOutfeedPack';
  packId: Scalars['ID'];
};

export type SalesBranch = {
  __typename: 'SalesBranch';
  id: Scalars['ID'];
};

export type SalesOrder = {
  __typename: 'SalesOrder';
  id: Scalars['ID'];
};

export type SalesOrderLine = {
  __typename: 'SalesOrderLine';
  id: Scalars['ID'];
};

export type ShippingProvider = {
  __typename: 'ShippingProvider';
  id: Scalars['ID'];
};

export type StockGroup = {
  __typename: 'StockGroup';
  id: Scalars['ID'];
};

export type StockItem = {
  __typename: 'StockItem';
  stockCode: Scalars['ID'];
};

export type StockLocation = {
  __typename: 'StockLocation';
  id: Scalars['ID'];
};

export type UpdateHandlesGetError = UpdateHandlesGetNotFoundError | UpdateHandlesGetNotModifiableError;

export type UpdateHandlesGetHandleInput = {
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /** ID of the file to update */
  fileId: Scalars['ID'];
  /**
   * The new name for the file. Even when null, this will rename the old file. This
   * does not affect the name of the blob, just the name of the file according to
   * the API.
   */
  filename?: InputMaybe<Scalars['String']>;
};

export type UpdateHandlesGetInput = {
  handles: Array<UpdateHandlesGetHandleInput>;
};

export type UpdateHandlesGetNotFoundError = UserError & {
  __typename: 'UpdateHandlesGetNotFoundError';
  message: Scalars['String'];
};

export type UpdateHandlesGetNotModifiableError = UserError & {
  __typename: 'UpdateHandlesGetNotModifiableError';
  message: Scalars['String'];
};

export type UpdateHandlesGetPayload = {
  __typename: 'UpdateHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UpdateHandlesGetError>;
};

/** A handle to a file in Azure Blob Storage. */
export type UploadHandle = {
  __typename: 'UploadHandle';
  /** The file record created. */
  file: File;
  /** URL which can be used to upload a file */
  uploadUrl: Scalars['String'];
};

export type UploadHandlesGetError = UploadHandlesGetInvalidInputError;

export type UploadHandlesGetHandleInput = {
  /** The file extension for the blob name, if any. */
  blobExtension?: InputMaybe<Scalars['String']>;
  /**
   * If not set, the blob will be stored internally as
   * "<some-uuid>.<blobExtension>", or if no blob file extension is present, just
   * "<some-uuid>".
   *
   * If set, the blob will be stored as "<prefix> <some-uuid>.<blobExtension>",
   * or if no blob file extension is present, just "<prefix> <some-uuid>"".
   */
  blobNamePrefix?: InputMaybe<Scalars['String']>;
  /**
   * The name of the container to place the blob in. This container does not
   * need to exist to be used - it will be created as required.
   */
  containerName: Scalars['String'];
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /**
   * If null, won't ever expire. If set, must be positive. Files will not be
   * deleted until after `expireDays` is met, although the deletion is not
   * immediate.
   */
  expireDays?: InputMaybe<Scalars['Int']>;
  /** Filename for the file according to the API, if any. */
  filename?: InputMaybe<Scalars['String']>;
  /**
   * Permissions that apply to the file. Only enforceable if file operations
   * happen through this api
   */
  permissions?: InputMaybe<FilePermissions>;
};

export type UploadHandlesGetInput = {
  handles: Array<UploadHandlesGetHandleInput>;
};

export type UploadHandlesGetInvalidInputError = UserError & {
  __typename: 'UploadHandlesGetInvalidInputError';
  message: Scalars['String'];
};

export type UploadHandlesGetPayload = {
  __typename: 'UploadHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UploadHandlesGetError>;
};

export type User = {
  __typename: 'User';
  id: Scalars['ID'];
};

export type UserError = {
  message: Scalars['String'];
};

export type WipSalesOrder = {
  __typename: 'WipSalesOrder';
  id: Scalars['ID'];
};

export type Workstation = {
  __typename: 'Workstation';
  id: Scalars['ID'];
};

export type CustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomersQuery = { __typename: 'Query', customers: Array<{ __typename: 'ProfileCustomer', id: ID, name: string }> };

export type CustomerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomerQuery = { __typename: 'Query', customer?: { __typename: 'ProfileCustomer', id: ID, name: string, notes: string, assignedProducts: Array<{ __typename: 'ProductProfile', id: ID, name: string }> } | null | undefined };

export type CreateCustomerMutationVariables = Exact<{
  input: ProfileCustomerCreateInput;
}>;


export type CreateCustomerMutation = { __typename: 'Mutation', result: { __typename: 'ProfileCustomerCreatePayload', customer?: { __typename: 'ProfileCustomer', id: ID, name: string, notes: string, assignedProducts: Array<{ __typename: 'ProductProfile', id: ID }> } | null | undefined, userErrors: Array<{ __typename: 'ProductProfileCreateInvalidNestingError', message: string } | { __typename: 'ProductProfileCreateNotFoundError', message: string } | { __typename: 'ProductProfileDuplicateInvalidNestingError', message: string } | { __typename: 'ProductProfileDuplicateNotFoundError', message: string } | { __typename: 'ProductProfileUpdateExcessivePayloadError', message: string } | { __typename: 'ProductProfileUpdateImagePlacementsNotFoundError', message: string } | { __typename: 'ProductProfileUpdateNotFoundError', message: string } | { __typename: 'ProductProfileUploadImageNotFoundError', message: string } | { __typename: 'ProductProfileUploadTechnicalFileNotFoundError', message: string } | { __typename: 'ProfileCustomerUpdateNotFoundError', message: string } | { __typename: 'UpdateHandlesGetNotFoundError', message: string } | { __typename: 'UpdateHandlesGetNotModifiableError', message: string } | { __typename: 'UploadHandlesGetInvalidInputError', message: string }> } };

export type UpdateCustomerMutationVariables = Exact<{
  input: ProfileCustomerUpdateInput;
}>;


export type UpdateCustomerMutation = { __typename: 'Mutation', result: { __typename: 'ProfileCustomerUpdatePayload', customer?: { __typename: 'ProfileCustomer', id: ID, name: string, notes: string } | null | undefined, userErrors: Array<{ __typename: 'ProfileCustomerUpdateNotFoundError', message: string }> } };

export type DeleteCustomerMutationVariables = Exact<{
  input: ProfileCustomerDeleteInput;
}>;


export type DeleteCustomerMutation = { __typename: 'Mutation', result: { __typename: 'ProfileCustomerDeletePayload', userErrors: Array<{ __typename: 'ProductProfileCreateInvalidNestingError', message: string } | { __typename: 'ProductProfileCreateNotFoundError', message: string } | { __typename: 'ProductProfileDuplicateInvalidNestingError', message: string } | { __typename: 'ProductProfileDuplicateNotFoundError', message: string } | { __typename: 'ProductProfileUpdateExcessivePayloadError', message: string } | { __typename: 'ProductProfileUpdateImagePlacementsNotFoundError', message: string } | { __typename: 'ProductProfileUpdateNotFoundError', message: string } | { __typename: 'ProductProfileUploadImageNotFoundError', message: string } | { __typename: 'ProductProfileUploadTechnicalFileNotFoundError', message: string } | { __typename: 'ProfileCustomerUpdateNotFoundError', message: string } | { __typename: 'UpdateHandlesGetNotFoundError', message: string } | { __typename: 'UpdateHandlesGetNotModifiableError', message: string } | { __typename: 'UploadHandlesGetInvalidInputError', message: string }> } };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename: 'Query', parentProducts: Array<{ __typename: 'ProductProfile', id: ID, name: string, ourCode: string, children: Array<{ __typename: 'ProductProfile', id: ID, name: string, ourCode: string, assignedCustomer?: { __typename: 'ProfileCustomer', id: ID, name: string } | null | undefined }> }> };

export type ProductQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductQuery = { __typename: 'Query', product?: { __typename: 'ProductProfile', id: ID, lastUpdated: string, name: string, notes: string, endUsage: string, gradeSpec: string, lengthSpec: string, machineSpec: string, mm2?: number | null | undefined, ourCode: string, sizeTolerance: string, customerReference: string, parent?: { __typename: 'ProductProfile', id: ID } | null | undefined, children: Array<{ __typename: 'ProductProfile', id: ID, assignedCustomer?: { __typename: 'ProfileCustomer', id: ID, name: string } | null | undefined }>, stacking?: { __typename: 'ProductProfileStacking', id: ID, packWidth: number, packHeight: number, subpack: string } | null | undefined, assignedCustomer?: { __typename: 'ProfileCustomer', id: ID, name: string } | null | undefined, extraRequirements: Array<{ __typename: 'ProductProfileExtraRequirement', id: ID, key: string, value: string }>, imageFile?: { __typename: 'File', id: ID, info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } } | null | undefined, technicalFile?: { __typename: 'File', id: ID, name?: string | null | undefined, info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } } | null | undefined, imagePlacement: { __typename: 'ProductProfileImagePlacement', id: ID, horizontal: number, vertical: number, scale: number } } | null | undefined };

export type CreateProductMutationVariables = Exact<{
  input: ProductProfileCreateInput;
}>;


export type CreateProductMutation = { __typename: 'Mutation', result: { __typename: 'ProductProfileCreatePayload', product?: { __typename: 'ProductProfile', id: ID, lastUpdated: string, name: string, notes: string, endUsage: string, gradeSpec: string, lengthSpec: string, machineSpec: string, mm2?: number | null | undefined, ourCode: string, sizeTolerance: string, customerReference: string, stacking?: { __typename: 'ProductProfileStacking', id: ID } | null | undefined, assignedCustomer?: { __typename: 'ProfileCustomer', id: ID } | null | undefined, extraRequirements: Array<{ __typename: 'ProductProfileExtraRequirement', id: ID }>, imageFile?: { __typename: 'File', id: ID } | null | undefined, technicalFile?: { __typename: 'File', id: ID } | null | undefined, children: Array<{ __typename: 'ProductProfile', id: ID }> } | null | undefined, userErrors: Array<{ __typename: 'ProductProfileCreateInvalidNestingError', message: string } | { __typename: 'ProductProfileCreateNotFoundError', message: string }> } };

export type UpdateProductMutationVariables = Exact<{
  input: ProductProfileUpdateInput;
}>;


export type UpdateProductMutation = { __typename: 'Mutation', result: { __typename: 'ProductProfileUpdatePayload', product?: { __typename: 'ProductProfile', id: ID, lastUpdated: string, name: string, notes: string, endUsage: string, gradeSpec: string, lengthSpec: string, machineSpec: string, mm2?: number | null | undefined, ourCode: string, sizeTolerance: string, customerReference: string, stacking?: { __typename: 'ProductProfileStacking', id: ID, packWidth: number, packHeight: number, subpack: string } | null | undefined, assignedCustomer?: { __typename: 'ProfileCustomer', id: ID, name: string } | null | undefined, extraRequirements: Array<{ __typename: 'ProductProfileExtraRequirement', id: ID, key: string, value: string }>, imageFile?: { __typename: 'File', id: ID, info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } } | null | undefined, technicalFile?: { __typename: 'File', id: ID, name?: string | null | undefined, info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } } | null | undefined, imagePlacement: { __typename: 'ProductProfileImagePlacement', id: ID, horizontal: number, vertical: number, scale: number } } | null | undefined, userErrors: Array<{ __typename: 'ProductProfileUpdateExcessivePayloadError', message: string } | { __typename: 'ProductProfileUpdateNotFoundError', message: string }> } };

export type DuplicateProductMutationVariables = Exact<{
  input: ProductProfileDuplicateInput;
}>;


export type DuplicateProductMutation = { __typename: 'Mutation', result: { __typename: 'ProductProfileDuplicatePayload', newProduct?: { __typename: 'ProductProfile', id: ID } | null | undefined, userErrors: Array<{ __typename: 'ProductProfileDuplicateInvalidNestingError', message: string } | { __typename: 'ProductProfileDuplicateNotFoundError', message: string }> } };

export type DeleteProductMutationVariables = Exact<{
  input: ProductProfileDeleteInput;
}>;


export type DeleteProductMutation = { __typename: 'Mutation', result: { __typename: 'ProductProfileDeletePayload', userErrors: Array<{ __typename: 'ProductProfileCreateInvalidNestingError', message: string } | { __typename: 'ProductProfileCreateNotFoundError', message: string } | { __typename: 'ProductProfileDuplicateInvalidNestingError', message: string } | { __typename: 'ProductProfileDuplicateNotFoundError', message: string } | { __typename: 'ProductProfileUpdateExcessivePayloadError', message: string } | { __typename: 'ProductProfileUpdateImagePlacementsNotFoundError', message: string } | { __typename: 'ProductProfileUpdateNotFoundError', message: string } | { __typename: 'ProductProfileUploadImageNotFoundError', message: string } | { __typename: 'ProductProfileUploadTechnicalFileNotFoundError', message: string } | { __typename: 'ProfileCustomerUpdateNotFoundError', message: string } | { __typename: 'UpdateHandlesGetNotFoundError', message: string } | { __typename: 'UpdateHandlesGetNotModifiableError', message: string } | { __typename: 'UploadHandlesGetInvalidInputError', message: string }> } };

export type MainProductInfoFragment = { __typename: 'ProductProfile', id: ID, lastUpdated: string, name: string, notes: string, endUsage: string, gradeSpec: string, lengthSpec: string, machineSpec: string, mm2?: number | null | undefined, ourCode: string, sizeTolerance: string, customerReference: string, stacking?: { __typename: 'ProductProfileStacking', id: ID, packWidth: number, packHeight: number, subpack: string } | null | undefined, assignedCustomer?: { __typename: 'ProfileCustomer', id: ID, name: string } | null | undefined, extraRequirements: Array<{ __typename: 'ProductProfileExtraRequirement', id: ID, key: string, value: string }>, imageFile?: { __typename: 'File', id: ID, info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } } | null | undefined, technicalFile?: { __typename: 'File', id: ID, name?: string | null | undefined, info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } } | null | undefined, imagePlacement: { __typename: 'ProductProfileImagePlacement', id: ID, horizontal: number, vertical: number, scale: number } };

export type UploadProductImageFileMutationVariables = Exact<{
  input: ProductProfileUploadImageInput;
}>;


export type UploadProductImageFileMutation = { __typename: 'Mutation', result: { __typename: 'ProductProfileUploadImagePayload', uploadUrl?: string | null | undefined, userErrors: Array<{ __typename: 'ProductProfileUploadImageNotFoundError', message: string }> } };

export type UploadProductTechnicalFileMutationVariables = Exact<{
  input: ProductProfileUploadTechnicalFileInput;
}>;


export type UploadProductTechnicalFileMutation = { __typename: 'Mutation', result: { __typename: 'ProductProfileUploadTechnicalFilePayload', uploadUrl?: string | null | undefined, userErrors: Array<{ __typename: 'ProductProfileUploadTechnicalFileNotFoundError', message: string }> } };

export type UpdateProfileImagePlacmentMutationVariables = Exact<{
  input: ProductProfileUpdateImagePlacementsInput;
}>;


export type UpdateProfileImagePlacmentMutation = { __typename: 'Mutation', result: { __typename: 'ProductProfileUpdateImagePlacementsPayload', products?: Array<{ __typename: 'ProductProfile', imagePlacement: { __typename: 'ProductProfileImagePlacement', id: ID, horizontal: number, vertical: number, scale: number } }> | null | undefined, userErrors: Array<{ __typename: 'ProductProfileUpdateImagePlacementsNotFoundError', message: string }> } };

export const MainProductInfoFragmentDoc = gql`
    fragment MainProductInfo on ProductProfile {
  id
  lastUpdated
  name
  notes
  endUsage
  gradeSpec
  lengthSpec
  machineSpec
  mm2
  ourCode
  sizeTolerance
  stacking {
    id
    packWidth
    packHeight
    subpack
  }
  customerReference
  assignedCustomer {
    id
    name
  }
  extraRequirements {
    id
    key
    value
  }
  imageFile {
    id
    info {
      __typename
      ... on PresentFile {
        accessUrl
      }
    }
  }
  technicalFile {
    id
    name
    info {
      __typename
      ... on PresentFile {
        accessUrl
      }
    }
  }
  imagePlacement {
    id
    horizontal
    vertical
    scale
  }
}
    `;
export const CustomersDocument = gql`
    query Customers {
  customers: profileCustomers {
    id
    name
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, options);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const CustomerDocument = gql`
    query Customer($id: ID!) {
  customer: profileCustomer(id: $id) {
    id
    name
    notes
    assignedProducts {
      id
      name
    }
  }
}
    `;

/**
 * __useCustomerQuery__
 *
 * To run a query within a React component, call `useCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerQuery(baseOptions: Apollo.QueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
      }
export function useCustomerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerQuery, CustomerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomerQuery, CustomerQueryVariables>(CustomerDocument, options);
        }
export type CustomerQueryHookResult = ReturnType<typeof useCustomerQuery>;
export type CustomerLazyQueryHookResult = ReturnType<typeof useCustomerLazyQuery>;
export type CustomerQueryResult = Apollo.QueryResult<CustomerQuery, CustomerQueryVariables>;
export const CreateCustomerDocument = gql`
    mutation CreateCustomer($input: ProfileCustomerCreateInput!) {
  result: profileCustomerCreate(input: $input) {
    customer {
      id
      name
      notes
      assignedProducts {
        id
      }
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type CreateCustomerMutationFn = Apollo.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, options);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = Apollo.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = Apollo.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const UpdateCustomerDocument = gql`
    mutation UpdateCustomer($input: ProfileCustomerUpdateInput!) {
  result: profileCustomerUpdate(input: $input) {
    customer {
      id
      name
      notes
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UpdateCustomerMutationFn = Apollo.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, options);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = Apollo.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const DeleteCustomerDocument = gql`
    mutation DeleteCustomer($input: ProfileCustomerDeleteInput!) {
  result: profileCustomerDelete(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type DeleteCustomerMutationFn = Apollo.MutationFunction<DeleteCustomerMutation, DeleteCustomerMutationVariables>;

/**
 * __useDeleteCustomerMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerMutation, { data, loading, error }] = useDeleteCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerMutation, DeleteCustomerMutationVariables>(DeleteCustomerDocument, options);
      }
export type DeleteCustomerMutationHookResult = ReturnType<typeof useDeleteCustomerMutation>;
export type DeleteCustomerMutationResult = Apollo.MutationResult<DeleteCustomerMutation>;
export type DeleteCustomerMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerMutation, DeleteCustomerMutationVariables>;
export const ProductsDocument = gql`
    query Products {
  parentProducts: productProfiles(excludeChildren: true) {
    id
    name
    ourCode
    children {
      id
      name
      ourCode
      assignedCustomer {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a React component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProductDocument = gql`
    query Product($id: ID!) {
  product: productProfile(id: $id) {
    ...MainProductInfo
    parent {
      id
    }
    children {
      id
      assignedCustomer {
        id
        name
      }
    }
  }
}
    ${MainProductInfoFragmentDoc}`;

/**
 * __useProductQuery__
 *
 * To run a query within a React component, call `useProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const CreateProductDocument = gql`
    mutation CreateProduct($input: ProductProfileCreateInput!) {
  result: productProfileCreate(input: $input) {
    product {
      id
      lastUpdated
      name
      notes
      endUsage
      gradeSpec
      lengthSpec
      machineSpec
      mm2
      ourCode
      sizeTolerance
      stacking {
        id
      }
      customerReference
      assignedCustomer {
        id
      }
      extraRequirements {
        id
      }
      imageFile {
        id
      }
      technicalFile {
        id
      }
      children {
        id
      }
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($input: ProductProfileUpdateInput!) {
  result: productProfileUpdate(input: $input) {
    product {
      ...MainProductInfo
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    ${MainProductInfoFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const DuplicateProductDocument = gql`
    mutation DuplicateProduct($input: ProductProfileDuplicateInput!) {
  result: productProfileDuplicate(input: $input) {
    newProduct {
      id
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type DuplicateProductMutationFn = Apollo.MutationFunction<DuplicateProductMutation, DuplicateProductMutationVariables>;

/**
 * __useDuplicateProductMutation__
 *
 * To run a mutation, you first call `useDuplicateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProductMutation, { data, loading, error }] = useDuplicateProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateProductMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateProductMutation, DuplicateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateProductMutation, DuplicateProductMutationVariables>(DuplicateProductDocument, options);
      }
export type DuplicateProductMutationHookResult = ReturnType<typeof useDuplicateProductMutation>;
export type DuplicateProductMutationResult = Apollo.MutationResult<DuplicateProductMutation>;
export type DuplicateProductMutationOptions = Apollo.BaseMutationOptions<DuplicateProductMutation, DuplicateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($input: ProductProfileDeleteInput!) {
  result: productProfileDelete(input: $input) {
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const UploadProductImageFileDocument = gql`
    mutation UploadProductImageFile($input: ProductProfileUploadImageInput!) {
  result: productProfileUploadImage(input: $input) {
    uploadUrl
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UploadProductImageFileMutationFn = Apollo.MutationFunction<UploadProductImageFileMutation, UploadProductImageFileMutationVariables>;

/**
 * __useUploadProductImageFileMutation__
 *
 * To run a mutation, you first call `useUploadProductImageFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProductImageFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProductImageFileMutation, { data, loading, error }] = useUploadProductImageFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadProductImageFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadProductImageFileMutation, UploadProductImageFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProductImageFileMutation, UploadProductImageFileMutationVariables>(UploadProductImageFileDocument, options);
      }
export type UploadProductImageFileMutationHookResult = ReturnType<typeof useUploadProductImageFileMutation>;
export type UploadProductImageFileMutationResult = Apollo.MutationResult<UploadProductImageFileMutation>;
export type UploadProductImageFileMutationOptions = Apollo.BaseMutationOptions<UploadProductImageFileMutation, UploadProductImageFileMutationVariables>;
export const UploadProductTechnicalFileDocument = gql`
    mutation UploadProductTechnicalFile($input: ProductProfileUploadTechnicalFileInput!) {
  result: productProfileUploadTechnicalFile(input: $input) {
    uploadUrl
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UploadProductTechnicalFileMutationFn = Apollo.MutationFunction<UploadProductTechnicalFileMutation, UploadProductTechnicalFileMutationVariables>;

/**
 * __useUploadProductTechnicalFileMutation__
 *
 * To run a mutation, you first call `useUploadProductTechnicalFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProductTechnicalFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProductTechnicalFileMutation, { data, loading, error }] = useUploadProductTechnicalFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadProductTechnicalFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadProductTechnicalFileMutation, UploadProductTechnicalFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadProductTechnicalFileMutation, UploadProductTechnicalFileMutationVariables>(UploadProductTechnicalFileDocument, options);
      }
export type UploadProductTechnicalFileMutationHookResult = ReturnType<typeof useUploadProductTechnicalFileMutation>;
export type UploadProductTechnicalFileMutationResult = Apollo.MutationResult<UploadProductTechnicalFileMutation>;
export type UploadProductTechnicalFileMutationOptions = Apollo.BaseMutationOptions<UploadProductTechnicalFileMutation, UploadProductTechnicalFileMutationVariables>;
export const UpdateProfileImagePlacmentDocument = gql`
    mutation UpdateProfileImagePlacment($input: ProductProfileUpdateImagePlacementsInput!) {
  result: productProfileUpdateImagePlacements(input: $input) {
    products {
      imagePlacement {
        id
        horizontal
        vertical
        scale
      }
    }
    userErrors {
      ... on UserError {
        __typename
        message
      }
    }
  }
}
    `;
export type UpdateProfileImagePlacmentMutationFn = Apollo.MutationFunction<UpdateProfileImagePlacmentMutation, UpdateProfileImagePlacmentMutationVariables>;

/**
 * __useUpdateProfileImagePlacmentMutation__
 *
 * To run a mutation, you first call `useUpdateProfileImagePlacmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileImagePlacmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileImagePlacmentMutation, { data, loading, error }] = useUpdateProfileImagePlacmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileImagePlacmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileImagePlacmentMutation, UpdateProfileImagePlacmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileImagePlacmentMutation, UpdateProfileImagePlacmentMutationVariables>(UpdateProfileImagePlacmentDocument, options);
      }
export type UpdateProfileImagePlacmentMutationHookResult = ReturnType<typeof useUpdateProfileImagePlacmentMutation>;
export type UpdateProfileImagePlacmentMutationResult = Apollo.MutationResult<UpdateProfileImagePlacmentMutation>;
export type UpdateProfileImagePlacmentMutationOptions = Apollo.BaseMutationOptions<UpdateProfileImagePlacmentMutation, UpdateProfileImagePlacmentMutationVariables>;